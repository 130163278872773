.service-menus__change-account {
    & i {
        color: var(--neutrals-n7);
        font-size: 24px;
    }
}

#serviceMenus {
    float: right;
    padding-right: 15px;
}

#serviceMenu {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 2px;
    align-items: center;
}

.menu-scheme-sinistrom #serviceMenus {
    padding-right: 0;
}