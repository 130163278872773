@import 'colors.css';
@import 'typography.css';
@import 'buttons.css';
@import 'forms.css';
@import 'layout.css';
@import 'charts.css';

@font-face {
    font-family: 'FontAwesome';
    src: url('font/fontawesome-webfont.eot?v=3.2.1');
    src: url('font/fontawesome-webfont.eot?#iefix&v=3.2.1') format('embedded-opentype'),
        url('font/fontawesome-webfont.woff?v=3.2.1') format('woff'),
        url('font/fontawesome-webfont.ttf?v=3.2.1') format('truetype'),
        url('font/fontawesome-webfont.svg#fontawesomeregular?v=3.2.1') format('svg');
    font-weight: normal;
    font-style: normal;
}

:root {
    --brand-font-size-title: 20px;
    --brand-font-size-label: 18px;
    --brand-font-size-button: 14px;
    --brand-font-size-field: 13px;

    --margin-normal: 14px;
    --margin-double: calc(var(--margin-normal) * 2);
    --margin-half: calc(var(--margin-normal) / 2);

    --bold-weight: bold;
}

/* Set the size, needed for any sizes using rem units */
html {
    font-size: var(--brand-font-size-field);
}

/*
*   TOP LEVEL STYLE
*/

body {
    font-family: 'Roboto', sans-serif;
    margin: 0 auto;
    font-size: var(--brand-font-size-field);
    overflow-x: hidden;
    background-color: var(--neutrals-n0);
}

@media (max-width: 1200px) {
    body {
        overflow-x: auto;
    }
}

body.modal-open {
    /* Prevent the page from scrolling when a modal is open */ 
    overflow: hidden;
    position: fixed;
    width: 100%;

    /* Reserve space for the scrollbar that is going away */
    scrollbar-gutter: stable;
}

.modal-open .nav-line {
    /* Reserve space for the scrollbar that is going away */
    padding-right: calc(var(--gutter-width) + 15px);
}

/*
*   COLORS
*/
.inherit-color {
    color: inherit;
}

/*
*   POSITION STYLES
*/
.width-100 {
    width: 100%;
}

.width-90 {
    width: 90%;
}

.width-80 {
    width: 80%;
}

.width-70 {
    width: 70%;
}

.width-30 {
    width: 30%;
}

.width-20 {
    width: 20%;
}

.width-10 {
    width: 10%;
}

.top-margin {
    margin-top: var(--margin-normal);
}
.top-margin-half {
    margin-top: var(--margin-half);
}

.top-margin-double {
    margin-top: var(--margin-double);
}

.bottom-margin {
    margin-bottom: var(--margin-normal);
}

.overflow-x-auto {
    overflow-x: auto;
}

.left-margin {
    margin-left: 14px;
}

.right-margin {
    margin-right: 14px;
}

.no-label-margin {
    margin-top: 22px;
}

.fixed-bottom {
    position: fixed;
    bottom: 0%;
}

.navbar-brand {
    /* Make sure the logo is clickable */
    height: 80px;
}

/* make the object not clickable so the anchor is */
#nav_logo {
    pointer-events: none;
}

/*
*   FONT STYLES
*/

.page-title {
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 10px;
    padding-bottom: 10px;
    color: var(--color-gray-big-stone);
}

.section-title {
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 10px;
}

.sub-section-title {
    font-size: 18px;
    border-bottom: 1px solid #333;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.form-section-title {
    color: var(--color-gray-big-stone);
    font-size: 14px;
    font-weight: bold;
}

.headline-title {
    color: var(--color-gray-big-stone);
    font-size: var(--brand-font-size-title);
    font-weight: normal;
}

.table-header {
    color: var(--color-gray-big-stone);
    font-size: 13px;
    font-weight: bold;
}

.table-body {
    color: var(--color-gray-big-stone);
    font-size: 12px;
    font-weight: normal;
}

h1 {
    margin: 0;
}

h2 {
    margin: 0;
}

h3 {
    margin: 0;
}

.light {
    font-weight: 300;
}

.normal {
    font-weight: 400;
}

.bold {
    font-weight: 700;
}

.super-bold {
    font-weight: 900;
}

.text-super {
    vertical-align: super;
}

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.inline {
    display: inline;
}

.hidden {
    display: none;
}

/** START visually hidden */
.visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

/* Separate rule for compatibility, :focus-within is required on modern Firefox and Chrome */
input.visually-hidden:focus + label {
    outline: thin dotted;
}

input.visually-hidden:focus-within + label {
    outline: thin dotted;
}
/** END visually hidden */

.underline {
    border-bottom: 1px solid #333;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.no-underline {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

.massive-text {
    font-size: 25vh;
}

.subtext {
    font-size: 2vh;
}

.centered-horiz {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.v-top {
    vertical-align: top;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center !important;
}

.truncate-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.beta-tag {
    font-size: 12px;
    margin-left: 5px;
    vertical-align: super;
}

.beta-title-tag {
    font-size: 12px;
    margin-left: 5px;
}

.important-number {
    font-size: 2em;
    font-weight: 400;
    color: var(--color-primary);
}

.hover-able {
    text-decoration: underline;
    text-decoration-color: #aaaaaa;
    text-decoration-style: dotted;
}
.no-definition {
    color: #aaaaaa;
}

input[type='checkbox'].acknowledgement {
    margin-right: 10px;
}

/*
*   BOXED STYLES
*/
.raised {
    box-shadow: 1px 1px 1px 1px rgba(136, 136, 136, 0.5);
    background-color: var(--color-white-smoke);
    padding: 10px 10px 10px;
}

.boxed {
    border: 1px solid rgba(136, 136, 136, 1);

    /* GLOBAL BOX STYLE CHANGE */
    /* box-shadow: 1px 1px 2px rgba(136, 136, 136, .5); */
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);

    border-radius: 4px;
    background-color: var(--color-white-smoke);
    padding: 10px 10px 10px;
}

.boxed-soft {
    border: none;
}

.boxed-full {
    padding: 0;
}

.boxed-demo,
.boxed-light {
    background: var(--color-white);
}

.boxed-gray {
    background-color: rgba(216, 216, 216, 0.6);
    border-top: 1px solid #d4d4d4;
    border-bottom: 1px solid #d4d4d4;
    padding: 0;
}

.boxed-striped {
    background-color: #d5d5d5;
}

.boxed-dark {
    background-color: rgba(69, 69, 69, 1);
    color: var(--color-white);
}

.boxed-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
}

.boxed-info {
    color: #31708f;
    background-color: #d9edf7;
}

.boxed-disabled {
    background-color: #ddd;
    cursor: not-allowed;
}

/* LIST STYLES */

.boxed.boxed-list {
    padding-bottom: 10px;
}

.boxed.boxed-list ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
}

.boxed.boxed-list ul ul {
    padding-left: 20px;
}

.boxed.boxed-list li a {
    text-decoration: none;
    display: block;
    padding: 5px 5px 5px 0;
}

.boxed.boxed-list li .group {
    display: block;
    padding: 5px 5px 5px 0;
}

.boxed.boxed-list li a:hover {
    background-color: #e3e3e3;
}

.boxed.boxed-list li.active {
    background-color: #e3e3e3;
}

.boxed.boxed-list .list-header {
    font-size: 16px;
}

/*
*   LOADERS
*/
.primaryPageLoader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/*
*   ICONS
*/
.icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    text-indent: -9999em;
    background-color: transparent;
    background-image: url('/web/images/sprite-icons.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    outline: none;
    margin: 0;
    padding: 0;
    text-align: left;
    vertical-align: baseline;
    opacity: 0.8;
    border-bottom: none;
}

.icon.lrg {
    width: 24px;
    height: 24px;
}
.pointer {
    cursor: pointer;
}

.icon.no-pointer {
    cursor: none;
}

.icon.pointer {
    cursor: pointer;
}

.icon.v-top {
    vertical-align: top;
}

table tr .icon {
    vertical-align: middle;
}

button .icon {
    margin: -3px 5px -3px 0;
}
.button .icon {
    opacity: 1;
}
a {
    cursor: pointer;
}
a .icon:hover {
    opacity: 1;
}
a .icon {
    cursor: pointer;
}
i.icon {
    cursor: pointer;
}
i.icon:hover {
    opacity: 1;
}

.action-link:hover,
.action-link:focus {
    text-decoration: none;
}
.icon.status {
    background-image: url('/web/images/icons/status-edit.png');
}
.icon.status.live {
    background-image: url('/web/images/icons/status-production.png');
}
.icon.status.staging {
    background-image: url('/web/images/icons/status-staging.png');
}
.icon.status.disabled {
    background-image: url('/web/images/icons/status-disabled.png');
}
.icon.excel {
    background-image: url('/web/images/icons/excel.png');
}
.icon.excel.disabled {
    background-image: url('/web/images/icons/excel_disabled.png');
}
.icon.upload {
    background-image: url('/web/images/icons/table_go.png');
}
.icon.upload.disabled {
    background-image: url('/web/images/icons/table_go_disabled.png');
}
.icon.none {
    background-image: none;
    width: 0;
}
.icon.loader {
    margin: -3px 5px -3px 0;
    background-image: url('/web/images/loading_16x16.gif');
}
.icon.miniloader {
    margin: -3px 5px -3px 0;
    background-image: url('/web/images/loading_16x16.gif');
    background-position: center;
}
.icon.check {
    background-image: url('/web/images/flat/Check-16.png');
}
.icon.check.disabled {
    background-image: url('/web/images/flat/Check-16-Disabled.png');
}
.icon.stop {
    background-position: -16px 0;
}
.icon.plus {
    background-image: url('/web/images/flat/Add-New-16.png');
}
.icon.plus.disabled {
    background-image: url('/web/images/flat/Add-New-16-Disabled.png');
}
.icon.star {
    background-image: url('/web/images/flat/Star-16.png');
}
.icon.star.disabled {
    background-image: url('/web/images/flat/Star-16-Disabled.png');
}
.icon.user {
    background-image: url('/web/images/flat/User-16.png');
}
.icon.user.disabled {
    background-image: url('/web/images/flat/User-16-Disabled.png');
}
.icon.minus {
    background-image: url('/web/images/flat/Minus-16.png');
}
.icon.minus.square {
    background-image: url('/web/images/flat/Minus-16-SQ.png');
}
.icon.minus.simple {
    background-image: url('/web/images/flat/Minus-16-Simple.png');
}
.icon.minus.disabled {
    background-image: url('/web/images/flat/Minus-16-Disabled.png');
}
.icon.info {
    background-image: url('/web/images/flat/Message-Information-16.png');
}
.icon.info.white {
    background-image: url('/web/images/flat/Message-Information-16-White.png');
}
.icon.info.warn {
    background-image: url('/web/images/flat/Message-Information-16-Warn.png');
}
.icon.info.lrg {
    background-image: url('/web/images/flat/Message-Information-24.png');
}
.icon.info.lrg.disabled {
    background-image: url('/web/images/flat/Message-Information-24-Disabled.png');
}
.icon.play {
    background-image: url('/web/images/flat/Media-Play-16.png');
}
.icon.play.disabled {
    background-image: url('/web/images/flat/Media-Play-16-Disabled.png');
}
.icon.pause {
    background-image: url('/web/images/flat/Media-Pause-16.png');
}

.icon.graph {
    background-image: url('/web/images/flat/Graph-03-16.png');
}
.icon.graph.disabled {
    background-image: url('/web/images/flat/Graph-03-16-Disabled.png');
}
.icon.edit {
    cursor: pointer;
    background-image: url('/web/images/flat/Data-Edit-16.png');
}
.icon.edit.disabled {
    background-image: url('/web/images/flat/Data-Edit-16-Disabled.png');
}
.icon.edit.white {
    cursor: pointer;
    background-image: url('/web/images/flat/Data-Edit-16-white.png');
}
.icon.disk {
    background-position: -112px 0;
}
.icon.mag {
    background-position: -128px 0;
}
.icon.mag2 {
    background-image: url('/web/images/icons/search.png');
}
.icon.bang {
    background-image: url('/web/images/flat/Warning-Message-16.png');
}
.icon.bang.disabled {
    background-image: url('/web/images/flat/Warning-Message-16-Disabled.png');
}
.icon.error {
    background-image: url('/web/images/flat/Message-Information-16-Danger.png');
}
.icon.remove-opt {
    background-position: -192px 0;
}
.icon.hibernate_pending {
    background-position: -208px 0;
}
.icon.hibernate_pending.disabled {
    background-position: -208px -32px;
}
.icon.hibernate {
    background-position: -224px 0;
}
.icon.hibernate.disabled {
    background-position: -224px -32px;
}
.icon.copy {
    background-position: -256px 0;
}
.icon.question {
    background-image: url('/web/images/flat/Help-16.png');
}
.icon.question-dark {
    background-image: url('/web/images/flat/Help-dark-16.png');
    opacity: 1;
}
.icon.right {
    background-position: -288px 0;
}
.icon.buydata {
    background-position: -304px 0;
}
.icon.page {
    background-position: -320px 0;
}
.icon.page.disabled {
    background-position: -320px -32px;
}
.icon.chart {
    background-position: -336px 0;
}
.icon.printer {
    background-position: -352px 0;
}
.icon.printer.disabled {
    background-position: -352px -32px;
}
.icon.settings {
    cursor: pointer;
    background-image: url('/web/images/flat/Settings-02-16.png');
}
.icon.settings.disabled {
    cursor: pointer;
    background-image: url('/web/images/flat/Settings-02-16-Disabled.png');
}
.icon.left {
    background-position: -400px 0;
}
.icon.calculator {
    background-position: -416px 0;
}
.icon.calculator.disabled {
    background-position: -416px 32px;
}
.lotame_assist {
    color: var(--color-primary);
}
#lotame_assist_holder .lotame_assist {
    font-size: 25px;
    top: 0;
}

.icon.clipboard {
    float: right;
    width: 12px;
    height: 16px;
    opacity: 1;
    cursor: pointer;
    background-image: url('/web/images/flat/clipboard.svg');
    background-repeat: no-repeat;
    outline: none;
    margin: 0;
    padding: 0;
    background-size: 100% 100%;
    -o-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: cover;
}
.icon.audience {
    float: right;
    width: 16px;
    height: 16px;
    opacity: 1;
    cursor: pointer;
    background-image: url('/web/images/flat/audience.svg');
    background-repeat: no-repeat;
    outline: none;
    margin: 0;
    padding: 0;
    background-size: 100% 100%;
    -o-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: cover;
    color: #1c748a;
}
.icon.robot {
    float: right;
    width: 24px;
    height: 26px;
    opacity: 1;
    cursor: pointer;
    background-image: url('/web/images/flat/robot.svg');
    background-repeat: no-repeat;
    outline: none;
    margin: 0;
    padding: 0;
    background-size: 100% 100%;
    -o-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: cover;
}

.icon.html {
    background-image: url('/web/images/flat/Source-Code-16.png');
}
.icon.html.disabled {
    background-image: url('/web/images/flat/Source-Code-16-Disabled.png');
}
.icon.report {
    background-image: url('/web/images/flat/Document-01-16.png');
}
.icon.report.disabled {
    background-position: -448px -32px;
}
.icon.undo {
    background-image: url('/web/images/icons/arrow_undo.png');
}
.icon.refresh {
    background-image: url('/web/images/icons/arrow_refresh.png');
}
.icon.go {
    background-image: url('/web/images/icons/link_go.png');
}
.icon.bullet-minus {
    background-image: url('/web/images/icons/bullet_toggle_minus.png');
}
.icon.bullet-plus {
    background-image: url('/web/images/icons/bullet_toggle_plus.png');
}
.icon.hierbrowse {
    background-image: url('/web/images/icons/folder_explore.png');
} /* used for link to browse hierarchies */
.icon.menu {
    background-image: url('/web/images/icons/menu-icon.png');
    vertical-align: text-bottom;
    opacity: 0.6;
}
.icon.label-add {
    background-image: url('/web/images/icons/light-grey-add.png');
} /* used for link to add label */
.icon.money {
    background-image: url('/web/images/flat/Dollar-16.png');
}
.icon.node-pricing {
    background-image: url('/web/images/icons/money_dollar.png');
}
.icon.node-unpricing {
    background-image: url('/web/images/icons/money_undollar.png');
}
.icon.goal-based-audience {
    background-image: url('/web/images/icons/sport_soccer.png');
}
.icon.email {
    background-image: url('/web/images/icons/email.png');
}
.icon.share {
    background-image: url('/web/images/icons/share-icon-16x16-grayscale.png');
}
.icon.syndicate {
    background-image: url('/web/images/flat/Share-01-16.png');
}
.icon.mag3 {
    background-image: url('/web/images/icons/search.png');
}

.icon.large {
    width: 32px;
    height: 24px;
    margin: 0;
}
.icon.scatter-plot {
    background-image: url('/web/images/icons/scatter-plot.png');
}
.icon.circle-packing {
    background-image: url('/web/images/icons/circle-packing.png');
}
.icon.column-list {
    background-image: url('/web/images/icons/column-list.png');
}
.icon.area-graph {
    background-image: url('/web/images/flat/Graph-02-16.png');
}
.icon.area-graph.disabled {
    background-image: url('/web/images/flat/Graph-02-16-Disabled.png');
}
.icon.id-card {
    background-image: url('/web/images/flat/Identity-Card-16.png');
}
.icon.id-card.disabled {
    background-image: url('/web/images/flat/Identity-Card-16-Disabled.png');
}
.icon.export {
    background-image: url('/web/images/flat/Data-Export-16.png');
}

.icon.overlap {
    background-image: url('/web/images/flat/Layers-01-16.png');
}
.icon.expand {
    background-image: url('/web/images/flat/Add-16-SQ.png');
}
.icon.collapse {
    background-image: url('/web/images/flat/Minus-16-SQ.png');
}
.icon.glass {
    background-image: url('/web/images/flat/Hour-Glass-16.png');
}
.icon.cal {
    background-image: url('/web/images/flat/Calendar-Date-04-16.png');
}
.icon.arrow-right {
    background-image: url('/web/images/flat/Arrowhead-Right-01-16-green.png');
}
.icon.expand-screen {
    background-image: url('/web/images/flat/Full-Screen-Expand-16-green.png');
}
.icon.data {
    background-image: url('/web/images/flat/Data-Information-16.png');
}

.icon.cancel {
    background-image: url('/web/images/flat/Cancel-16.png');
}
.icon.cancel.disabled {
    background-image: url('/web/images/flat/Cancel-16-Disabled.png');
}
.icon.tag {
    background-image: url('/web/images/flat/Tag-16-green.png');
}
.icon.default.tag {
    background-image: url('/web/images/flat/Tag-16.png');
}
.icon.tag.disabled {
    background-image: url('/web/images/flat/Tag-16-Disabled.png');
}
.icon.lab-group {
    background: url(/web/images/flat/Folder-New-01-Empty.png) no-repeat right transparent;
    height: 11px;
    width: 15px;
}

.icon.ignore {
    background-image: url('/web/images/flat/Data-Access-Restricted-16.png');
}
.icon.gear {
    background-image: url('/web/images/flat/Gear-16.png');
    vertical-align: middle;
}
.icon.command-refresh {
    background-image: url('/web/images/flat/Command-Refresh-01-16.png');
}

.icon.no-child,
td.no-child .folder {
    background-image: url('/web/images/flat/Folder-New-01-Empty.png');
}
.icon.has-child,
td.has-child .folder {
    background-image: url('/web/images/flat/Folder-New-01-Right.png');
}
.icon.has-child.selected,
td.selected .icon.has-child,
td.selected .folder {
    background-image: url('/web/images/flat/Folder-New-01-Down.png');
}

.disabled,
.icon.disabled {
    cursor: not-allowed;
}

.icon.last {
    margin-right: 0;
}

.icon.hierarchies {
    background-position: center;
}
.custom .floating_node_icon,
.icon.hierarchies.custom,
.icon.hierarchies.first_party {
    background: url(/web/images/flat/House-16.png) no-repeat center transparent;
}

.branded .floating_node_icon,
.icon.hierarchies.branded,
.icon.hierarchies.precision {
    background: url(/web/images/flat/Shopping-Cart-02-16.png) no-repeat center transparent;
}

.standard .floating_node_icon,
.icon.hierarchies.standard,
.icon.hierarchies.scale,
.icon.hierarchies.anti_fraud,
.icon.hierarchies.geo {
    background: url(/web/images/flat/Globe-16.png) no-repeat center transparent;
}

.shared .floating_node_icon,
.icon.hierarchies.shared,
.icon.hierarchies.showcased,
.icon.hierarchies.syndicate_dmp {
    background: url(/web/images/flat/Share-01-16.png) no-repeat center transparent;
    vertical-align: bottom;
}

.group .floating_node_icon,
.icon.hierarchies.group {
    background: url(/web/images/flat/Folder-New-01-Empty.png) no-repeat right transparent;
}

/*
Allow icon and glyphicon class to coexist
*/
.icon.glyphicon {
    background-image: initial;
    background-position: initial;
    background-repeat: initial;
    margin: initial;
    text-align: initial;
    width: initial;
    height: initial;
    text-indent: initial;
    font-family: 'Glyphicons Halflings';
}
.icon.glyphicon.disabled {
    color: #eeeeee;
}

/*
*   BOOTSTRAP OVERRIDE AND ACESSORIES
*/
.row-padded {
    padding-top: 5px;
    padding-bottom: 5px;
}

.row.match-my-cols {
    overflow: hidden;
}

.row.match-my-cols [class*='col-'] {
    margin-bottom: -99999px;
    padding-bottom: 99999px;
}

.page-scheme-veteris .toggle-handle:hover {
    background-color: white;
}

.page-scheme-veteris .btn:focus {
    outline: none;
    outline-style: none;
}

.page-scheme-veteris .btn:active {
    outline: none;
    outline-style: none;
    box-shadow: inset 1px 1px 2px rgba(136, 136, 136, 0.5);
}

.btn-default,
.btn-default:focus {
    color: var(--color-default);
    background-color: var(--color-white);
    border-color: var(--fc-border-color);
}

.btn-default:hover {
    color: var(--color-white);
    background-color: var(--color-default);
    border-color: var(--fc-border-color);
}

.btn-default:hover .popover {
    color: var(--color-default);
}

.btn-default.toggle-on {
    color: var(--color-white);
    background-color: var(--color-default);
    border-color: var(--color-default);
}

.btn-warning,
.btn-warning:focus {
    color: var(--color-warning);
    background-color: var(--color-white);
    border-color: var(--color-warning);
}

.btn-warning:hover {
    color: var(--color-white);
    background-color: var(--color-warning);
    border-color: var(--color-warning);
}

.btn-warning.toggle-on {
    color: var(--color-white);
    background-color: var(--color-warning);
    border-color: var(--color-warning);
}

.btn-danger,
.btn-danger:focus {
    color: var(--color-danger);
    background-color: var(--color-white);
    border-color: var(--color-danger);
}

.btn-danger:hover {
    color: var(--color-white);
    background-color: var(--color-danger);
    border-color: var(--color-danger);
}

.btn-danger.toggle-on {
    color: var(--color-white);
    background-color: var(--color-danger);
    border-color: var(--color-danger);
}

.btn-success,
.btn-success:focus {
    color: var(--color-success);
    background-color: var(--color-white);
    border-color: var(--color-success);
}

.btn-success:hover {
    color: var(--color-white);
    background-color: var(--color-success);
    border-color: var(--color-success);
}

.btn-success.toggle-on {
    color: var(--color-white);
    background-color: var(--color-success);
    border-color: var(--color-success);
}

.btn-info,
.btn-info:focus {
    color: var(--color-info);
    background-color: var(--color-white);
    border-color: var(--color-info);
}

.btn-info:hover {
    color: var(--color-white);
    background-color: var(--color-info);
    border-color: var(--color-info);
}

.btn-info.toggle-on {
    color: var(--color-white);
    background-color: var(--color-info);
    border-color: var(--color-info);
}

.btn-pink {
    background-color: var(--color-violet-persian-pink);
    color: var(--color-white);
}

.btn-orange {
    background-color: var(--color-orange-dark-tangerine);
    color: var(--color-white);
}

.toggle.btn[disabled] .btn {
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
}

@media (min-width: 992px) {
    .modal-lg {
        width: 900px;
    }
}

.modal-content {
    border-radius: 2px;
}

.badge {
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid;
    color: #333;
    font-weight: 400;
}

.badge-danger {
    color: var(--color-danger);
}

.badge-warning {
    color: var(--color-warning);
}

.badge-success {
    color: var(--color-primary);
}

.alert {
    border-radius: 2px;
}

.alert[data-alert-style="slim"] {
    margin-bottom: 0;
}

/*
  Reserve the space to prevent reflows
 */
.alert-holder {
    height: 70px;
}

.progress {
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 0;
}

.progress-bar {
    box-shadow: 0;
    background-color: #bedbbe;
    color: #000;
}

.bootstrap-tagsinput {
    border: 1px solid #333 !important;
    border-radius: 2px !important;
}

.lotacon {
    min-width: 21px;
    text-align: center;
}

.jstree a.lotacon {
    color: #565656;
}

.icon-info,
.lotacon-info,
.jstree a.lotacon-info {
    color: var(--color-info);
}

.icon-primary,
.lotacon-primary,
.lotacon.help-icon,
.jstree a.lotacon-primary {
    color: var(--color-primary);
    vertical-align: bottom;
    font-size: 1em;
}

.help-icon.lotacon-stacked-overlay-sm:after {
    /* Override the position so that the question mark shows up in the middle */
    left: 23%;
}

.lotacon.help-icon {
    cursor: help;
}

.icon-success,
.lotacon-success,
.jstree a.lotacon-success {
    color: #3c763d;
}

.icon-warning,
.lotacon-warning,
.jstree a.lotacon-warning {
    color: var(--color-warning);
}

.icon-danger,
.lotacon-danger,
.jstree a.lotacon-danger {
    color: var(--color-danger);
}

.lotacon-minus-sign {
    color: var(--color-danger);
}

.lotacon.lotacon-robot {
    top: 0px;
    margin-right: 2px;
    margin-left: 2px;
}
.lotacon.lotacon-duplicate {
    color: var(--color-green-observatory);
}

/* Fix for icon widths in column manager */
.col-manager-holder li i.icon-checkbox.lotacon {
    min-width: unset;
    margin-right: 2px;
}

/*
*   CHOSEN SELECT
*/

.chosen-single.drop-up {
    border-top: none;
}

.chosen-drop.drop-up {
    bottom: 32px;
    top: auto !important;
    border: solid #333;
    border-top-style: solid !important;
    border-width: 1px 1px 0 1px !important;
    margin-top: -1px;
    border-radius: 4px 4px 0px 0px !important;
}

.page-scheme-veteris .chosen-container-single .chosen-single {
    background-color: var(--color-white);
}

/* fix for bad Chosen search text style */
.page-scheme-veteris .chosen-search input[type='text'] {
    background-image: none;
}

.page-scheme-veteris .chosen-container .chosen-drop {
    border: 1px solid #333;
}

.page-scheme-veteris .chosen-container-single .chosen-single {
    border: 1px solid var(--fc-border-color);
    border-radius: 2px;
    color: var(--fc-font-color);
    box-shadow: 1px 1px 2px rgba(136, 136, 136, 0.5);
}

.page-scheme-veteris .chosen-container .chosen-results li.highlighted {
    background-color: #f5f5f5;
    opacity: 1;
    color: #000;
}

.form-select-validation {
    position: absolute;
    top: 30px;
    right: 40px;
    display: none;
}

.has-error .form-select-validation {
    color: var(--color-danger-);
    display: block;
}

.has-error .control-label,
 .has-error .help-block{
    color: var(--color-danger);
}

.has-error .input-group-addon {
    color: var(--color-danger-darker);
    background-color: var(--color-danger-lighter);
}

.page-scheme-veteris .has-error .chosen-container-single .chosen-single {
    border-color: var(--color-danger);
}

.page-scheme-veteris .has-success .chosen-container-single .chosen-single {
    border-color: var(--color-success);
}

.page-scheme-veteris .chosen-container-single .chosen-drop {
    border-radius: 0 0 2px 2px;
    border-top: none;
}

.page-scheme-veteris .chosen-container-multi .chosen-choices li.search-choice {
    border-radius: 2px;
}
/* to make ClientSelect look reasonable in a form context */
#client-selector ul.chosen-choices {
    padding: 0;
}
#client-selector li.search-choice {
    line-height: 16px;
    background-color: #fafafa;
    border: 1px solid #ccc;
    background-image: unset;
    vertical-align: middle;
}

/*
 * Custom form elements
 */
.text_wizard_placeholder {
    font-style: italic;
    color: #aaa;
}

.text_wizard_container > .text_wizard_button {
    width: 20%;
    padding: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.text_wizard_container > .text_wizard_body {
    display: inline-block;
    padding: 6px;
    width: 80%;
    background-color: var(--color-white-smoke);
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    border: 1px solid #888;
    line-height: 20px;
    margin: 0 0 0 -1px;
    box-shadow: 1px 1px 2px rgba(136, 136, 136, 0.5);
    vertical-align: top;
    min-height: 34px;
}

.page-scheme-veteris .select2-container--default .select2-search--dropdown .select2-search__field {
    border: solid 1px var(--fc-border-color);
}

.page-scheme-veteris .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 3px;
}

.padded {
    padding: 0 5px;
}

.padding-h-10 {
    padding: 0 10px;
}

.padding-h-5 {
    padding: 0 5px;
}

.padding-h-15 {
    padding: 0 15px;
}

.padding-xs {
    padding: 5px;
}

.padding-10 {
    padding: 10px;
}

.padding-15 {
    padding: 15px;
}

.padding-top {
    padding-top: 15px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-5 {
    padding-bottom: 5px;
}

.font-italics {
    font-style: italic;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

/* offsets the negative padding for a row */
.form-group.row-container {
    padding-left: 15px;
    padding-right: 15px;
}

.basic-filter-wrapper {
    position: relative;
}
.basic-filter-wrapper a.clear {
    position: absolute;
    right: 12px;
    width: 10px;
    font-size: 20px;
    color: gray;
    top: 10%;
}

.text-standard,
.text-scale {
    color: var(--color-default);
}

.bg-scale,
.bg-standard {
    background-color: var(--color-default);
}

.text-firstParty,
.text-custom {
    color: var(--color-primary);
}

.bg-custom {
    background-color: var(--color-primary);
}

.text-branded {
    color: #b34fc5;
}

.bg-branded {
    background-color: #b34fc5;
}

.text-shared {
    color: #00966c;
}

.bg-shared {
    background-color: #00966c;
}

.text-precision {
    color: #e32726;
}

.bg-precision {
    background-color: #e32726;
}

.flex-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

/*
From https://stackoverflow.com/a/25517025
*/
.vertical-align {
    display: flex;
    flex-direction: row;
}

.vertical-align > [class^='col-'],
.vertical-align > [class*=' col-'] {
    display: flex;
    align-items: center; /* Align the flex-items vertically */
    justify-content: center; /* Optional, to align inner flex-items
                              horizontally within the column  */
}

/*
    Loading
 */
.state-loading .content {
    --loading-color: var(--color-gray-chateau);
}
.state-loading .content,
.state-loading .content-label {
    background: var(--loading-color);
    border-radius: 3px;
    border-color: var(--loading-color);
    color: var(--loading-color);
}

.state-loading .lotacon {
    display: none;
}

.state-loading {
    animation-duration: 4s;
    animation-name: fade;
    animation-iteration-count: infinite;
}

@keyframes fade {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}

/*
    End Loading
 */

.brand-color-light-green {
    color: var(--color-primary);
}

.brand-color-dark-green {
    color: var(--color-primary-darker);
}

.brand-color-light-blue {
    color: var(--color-blue-robin-egg);
}

.brand-color-dark-blue {
    color: var(--color-blue-robin-egg);
}

.brand-color-yellow {
    color: var(--color-yellow-tangerine);
}

.brand-color-orange {
    color: var(--color-orange-dark-tangerine);
}

.brand-color-red {
    color: var(--color-red-alizarin);
}

.brand-color-gray {
    color: var(--color-gray-chateau);
}

.brand-color-purple {
    color: var(--color-violet-medium-orchid);
}

.brand-color-pink {
    color: var(--color-violet-persian-pink);
}

.color-audiences {
    color: var(--entity-color-audiences);
}

.color-behaviors {
    color: var(--entity-color-behaviors);
}

.color-campaigns {
    color: var(--entity-color-campaigns);
}

.color-models {
    color: var(--entity-color-models);
}

.color-profiles {
    color: var(--entity-color-profiles);
}

.background-color-audiences {
    background-color: var(--entity-color-audiences);
}

.background-color-behaviors {
    background-color: var(--entity-color-behaviors);
}

.background-color-campaigns {
    background-color: var(--entity-color-campaigns);
}

.background-color-models {
    background-color: var(--entity-color-models);
}

.background-color-profiles {
    background-color: var(--entity-color-profiles);
}

.background-color-system {
    background-color: var(--entity-color-system);
}

 .text-muted {
    color: var(--color-muted);
}
.text-default {
    color: var(--color-default);
}
.text-primary {
    color: var(--color-primary);
}
.text-success {
    color: var(--color-success);
}
.text-info {
    color: var(--color-info);
}
.text-warning {
    color: var(--color-warning);
}
.text-danger {
    color: var(--color-danger);
}

.label-muted {
    background-color: var(--color-muted);
}

.label-default {
    background-color: var(--color-default);
}

.label-success {
    background-color: var(--color-success);
}

.label-info {
    background-color: var(--color-info);
}

.label-warning {
    background-color: var(--color-warning);
}

.label-danger {
    background-color: var(--color-danger);
} 


/*
* Override Bootstrap styles
 */
.page-scheme-veteris hr {
    border-color: var(--color-gray-chateau);
    margin-top: 10px;
    margin-bottom: 10px;
}

footer.page-footer {
    padding: 10px 0;
    background-color: var(--background);
}

.container-fluid {
    background-color: var(--background);
}

 [data-nav-style="TOP"] .container-fluid {
    background-color: var(--neutrals-n0);
}

.content-container {
    background-color: var(--background);
}

.more-info:not([title='']),
.more-info:not([data-original-title='']) {
    border-bottom: dotted 1px;
    color: unset;
    text-decoration: none;
}

.more-info:not([title='']):hover,
.more-info:not([data-original-title='']):hover {
    cursor: help;
}
.wrappable {
    white-space: normal;
}

.assigned-client-list {
    max-height: 30vh;
    overflow-y: auto;
}

.drop-left .dropdown-menu {
    left: unset;
    right: 0;
}

.popover {
    width: 100%;
}

#impersonateModal-content-holder {
    height: 30vh;
}

.impersonation {
    --searchBoxWidth: calc(100% - 75px);
}

.impersonation .tt-dropdown-menu {
    margin-left: 0;
}

.upsell-container {
    line-height: 20pt;
    font-weight: 400;
    font-size: 12pt;
    color: var(--color-gray-big-stone);
}

.no-data-state img {
    width: 160px;
    height: 160px;
}

.dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-left: 25px;
    margin-right: 5px;
    display: inline-block;
  }