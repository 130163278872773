
.menu-scheme-sinistrom {
    --leftNavWidth: 85px;
    --brandHeight: 140px;
    --navbar-color: var(--color-gray-big-stone);
    --searchBoxWidth: 400px;
    --gutter-width: 85px;
    --bold-weight: 500;
    --normal-weight: 300;
    --top-page-margin: 16px;

    --sidebar-background: var(--primary-p5);
    --sidebar-color: var(--neutrals-n6);

    --sidebar-selected-background: var(--primary-p4-light) ;
    --sidebar-selected-color: var(--color-white);

    --sidebar-current-page-background: var(--sidebar-background);
    --sidebar-current-page-color: var(--color-white);    

    --sidebar-hover-color: var(--primary-p3);

    --drawer-background: var(--color-white);
    --drawer-color: var(--neutrals-n10);
    --drawer-header-color: var(--neutrals-n9);

    --drawer-hover-background: var(--primary-p1);
    --drawer-hover-color: var(--neutrals-n10);

    --drawer-selected-color: var(--neutrals-n9);
}

@media (max-height: 700px) {
    .menu-scheme-sinistrom {
        --brandHeight: 100px;
    }
}

#pageContainer .menu-scheme-sinistrom .container-fluid {
    margin: 0;
    padding: 0; 
}


.menu-scheme-sinistrom .modal {
    /* Make the modals center on the content, not the menu */
    left: var(--leftNavWidth);
}

.menu-scheme-sinistrom .navbar {
    border-radius: 0;
    background-color: var(--sidebar-background);
    box-shadow: none;
        border: none;
}

.menu-scheme-sinistrom .navbar-fixed-left {
    
    position: fixed;
    top: var(--brandHeight);
    left: 0;

    width: var(--leftNavWidth);
    right: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    height: calc(100% - var(--brandHeight));

    /* Let the menu scroll if the screen gets too short */
    overflow-y: auto;
    overflow-x: hidden;
    gap: 10px;

    /* Be on top of the box shadow from the top nav bar */
    z-index: 5000;
}

.menu-scheme-sinistrom.page-container {
    display: grid;
    grid-template-columns: 85px auto;
}

.menu-scheme-sinistrom .navbar-fixed-left li.submenu-brand {
    margin-top: var(--top-page-margin);
    margin-bottom: 60px;
}

.submenu-brand {
    /* Fixed to match the rest of the menu */
    position: fixed;
    top: 0;
    left: 0;
    
    /* Matches the brand height */
    height: var(--brandHeight);

    background-color: var(--sidebar-background);
    width: var(--leftNavWidth);
    text-align: center;
    display: flex;
    justify-content: center;
}

.menu-scheme-sinistrom .top-nav {
    left: var(--leftNavWidth);
    background-color: var(--color-white);
    padding-top: 0;
}

.menu-scheme-sinistrom .nav-menu-feature .menuChev {
    color: var(--sidebar-color);
}

.menu-scheme-sinistrom .top-submenu.active .nav-menu-feature .menuChev {
    color: var(--sidebar-selected-color);
}


.menu-scheme-sinistrom .nav-menu-feature:focus {
    outline: none;
    text-decoration: none;
    user-select: none;
}

.nav-menu-icon-holder {
    display: none;
}

.menu-scheme-sinistrom .navbar-fixed-left .navbar-nav {

    text-align: left;
    float: unset;
    padding: 0;
}

.menu-scheme-sinistrom .navbar-fixed-left li {
    list-style: none;
    margin: auto;
    width: 100%;
    text-align: center;
    height: 60px;
}


.menu-scheme-sinistrom .navbar-fixed-left .top-submenu.selected {
    background-color: var(--sidebar-current-page-background);
    color: var(--sidebar-current-page-color);
    font-weight: 900;
}

.menu-scheme-sinistrom .navbar-fixed-left .top-submenu.selected .menuChev {
    color:var(--sidebar-current-page-color);
}

.menu-scheme-sinistrom .navbar-fixed-left .top-submenu.active {
    background-color: var(--sidebar-selected-background); 
    color: var(--sidebar-selected-color);    
}

.menu-scheme-sinistrom .navbar-fixed-left .top-submenu .menuChev:hover {
    text-decoration: none;
    color: var(--sidebar-hover-color);
}

.menu-scheme-sinistrom .navbar-fixed-left .top-submenu.active .menuChev:hover {
    /* Hovering while selected */
    text-decoration: none;
    color: var(--sidebar-selected-color);
}

.menu-scheme-sinistrom .nav-menu-feature-close:hover {
    color: var(--sidebar-hover-color);
}

.menu-scheme-sinistrom .nav-menu-feature-close:focus {
    outline: none;
    text-decoration: none;
    user-select: none;
}

.menu-scheme-sinistrom .nav-menu-icon-holder {
    display: block;
    font-size: 23px;
    margin-bottom: 5px;
}

.menu-scheme-sinistrom .nav-menu-icon-holder i {
    font-size: 1.8rem;
}

.menu-scheme-sinistrom .menuName {
    font-size: 11px;
}

.menu-scheme-sinistrom .search-icon {
    color: var(--neutrals-n7);
    font-size: 24px;
    padding-left: 10px;
}

.menu-scheme-sinistrom #searchNav {
    display: inline-block;
    padding-left: 0;
}

.menu-scheme-sinistrom #serviceMenus {
    display: flex;
    gap: 8px;
    flex-direction: row;
    align-items: center;
}

.menu-scheme-sinistrom #serviceMenus li:first-child {
    order: 1;
}

.menu-scheme-sinistrom #serviceMenus li:last-child {
    order: 0;
}

.menu-scheme-sinistrom .secondary-nav {
    background-color: var(--drawer-background);
    display: none;

    color: var(--drawer-color);
    width: 285px;
    box-shadow: 12px -3px 8px -10px rgba(0, 0, 0, 0.25);

    position: fixed;
    top: 0;
    left: var(--leftNavWidth);
    right: auto;
    flex-flow: column nowrap;
    align-items: flex-start;
    height: 100%;
    z-index: 1035;
    overflow-y: auto;
}

.menu-scheme-sinistrom .secondary-nav .vertical-submenu {
    list-style: none;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 32px;
}

.menu-scheme-sinistrom .secondary-nav .menuItem,
.menu-scheme-sinistrom .menuNavLink {
    color: var(--drawer-color);
    font-size: 16px;
    line-height: 16px;
}

.menu-scheme-sinistrom .menuNavLink {
    color: var(--drawer-color);
    width: 100%;
    display: inline-block;
    padding: 9px 16px;
}

.menu-scheme-sinistrom .btn .nav-create-icon.lotacon {
    height: 17px;
    width: 17px;
    top: 2px;
}

.menu-scheme-sinistrom .menuNavLink:hover {
    text-decoration: none;
}
.vertical-submenu>li[role="presentation"] {
    margin-bottom: 8px;    
}

.vertical-submenu > li[role="presentation"]:hover {
    background-color: var(--drawer-hover-background);
    color: var(--drawer-color);
}

.vertical-submenu .nav-menu-link {
    width: 100%;
}

.create-action .nav-menu-link {
    /* Except here, we don't want padding */
    padding: 0;
}

.menu-scheme-sinistrom .secondary-menu-header {
    text-transform: uppercase;
    color: var(--drawer-header-color);
    font-size: 16px;
    font-weight: var(--bold-weight);
    line-height: 27px;
    margin-bottom: 30px;
}

.menu-scheme-sinistrom .secondary-section-header {
    display: grid;
    grid-template-columns: 1fr 72px;

    text-transform: uppercase;
    color: var(--drawer-header-color);
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.8px;

    border-top: solid 1px var(--neutrals-n4);
    padding-top: 20px;
    margin-top: 20px;
    margin-bottom: 16px;
}

.menu-scheme-sinistrom .create-action {
    justify-self: end;
    float: right;
    --btn-text-color: var(--color-white);
    --btn-background-color: var(--primary-p4);
}

.menu-scheme-sinistrom .create-action .btn-create-link {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;

    padding: 1px;
    border-width: 0;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
}

.menu-scheme-sinistrom .create-action .btn-create-link:hover {
    color: var(--color-white);
}
.menu-scheme-sinistrom .create-action .nav-menu-link-text {
    display: none;
    user-select: none;
}

/* Change the color when the button is clicked */
.menu-scheme-sinistrom .create-action .btn-create-link:focus {
    background-color: var(--primary-p3);
}

.menu-scheme-sinistrom .create-action:hover .nav-menu-link-text {
    display: inline-block;
    padding-left: 3px;
    padding-right: 3px;
}

.menu-scheme-sinistrom .vertical-submenu .secondary-section-header:nth-of-type(2) {
    border-top: none;
}

/* Clears the above margin for the first one in a list */
.menu-scheme-sinistrom .vertical-submenu li:first-child .secondary-section-header {
    margin-top: 0;
}

.nav-menu-feature-close {
    color: var(--drawer-color);
    position: absolute;
    top: 8px;
    right: 16px;
    font-size: 6px;
}

/************
* Search
************/
#global_search {
    --modalWidth: calc(100vw - var(--leftNavWidth));
    --searchBoxWidth: calc(var(--modalWidth) - 185px);
    top: 0;
}

#global_search .modal-content {
    width: var(--modalWidth);
    padding: 0;
    border-radius: 0;
}

#global_search .modal-dialog.modal-dynamic {
    min-width: var(--modalWidth);
    width: var(--modalWidth);
    margin: 0;
}

#global_search .modal-header {
    display: none;
}

#global_search .modal-body {
    overflow: visible;
    width: var(--modalWidth);
}

#global_search .modal-body .default-loader {
    display: none;
}

#global_search .modal-footer {
    display: none;
}

#global_search .suggestionsFooter {
    background-color: var(--color-white);
    box-sizing: border-box;
    cursor: pointer;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-top: 10px;
    text-align: left;
    width: 100%;
}

#global_search .suggestionsFooter a,
#global_search .typeAheadValue {
    color: var(--color-primary);
}

.suggestionsHeader {
    box-sizing: border-box;
    color: var(--color-white);
    padding-bottom: 10px;
    padding-left: 10px;
    padding-top: 10px;
    text-align: left;
    width: 100%;
}

.menu-scheme-sinistrom .global-search #searchNav {
    padding-top: 0;
    padding-left: 15px;
}

.menu-scheme-sinistrom .global-search #searchNav .search-close {
    top: 8px;
    margin-right: 20px;
    font-size: 25px;
}

.menu-scheme-sinistrom .global-search-box {
    font-size: 20px;
    height: 30px;
    width: var(--searchBoxWidth);
    margin-left: 15px;
}
.menu-scheme-sinistrom .global-search .search-go {
    top: 12px;
    font-size: 27px;
}

.menu-scheme-sinistrom .global-search-box:focus-visible {
    outline: none;
}

.global-search-shortcut {
    color: var(--color-gray-chateau);
    font-size: 10px;
}

.menu-scheme-sinistrom .typeAheadResult {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 90px;
}

.menu-scheme-sinistrom .typeAheadResult i {
    color: var(--color-gray-big-stone);
}

.menu-scheme-sinistrom .typeAheadResultContent {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.menu-scheme-sinistrom .typeAheadResultContent .archive-badge {
    vertical-align: super;
}

.menu-scheme-sinistrom .typeAheadName {
    /* Leave space for icons, a badge, and the id */
    max-width: calc(var(--searchBoxWidth) - 180px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.menu-scheme-sinistrom .type-ahead-holder {
    /* aligns the ids in brackets */
    vertical-align: super;
}

.menu-scheme-sinistrom .tt-suggestion,
.menu-scheme-sinistrom .noResultsFoundMessage {
    background-color: var(--color-white);
    padding: 3px 8px;
    border-bottom: 1px solid #ddd;
}

.menu-scheme-sinistrom .tt-dropdown-menu {
    border: none;
    box-shadow: 2px -2px 8px 4px rgba(0, 0, 0, 0.25);
    width: calc(var(--searchBoxWidth) + 70px);
    overflow-y: auto;
    max-height: 90vh;
    margin-left: -15px;
    margin-top: 10px;
}

.menu-scheme-sinistrom .suggestionsHeader {
    text-align: left;
    background-color: var(--navbar-color);
    color: var(--color-white);
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid #ddd;
}

.menu-scheme-sinistrom .tt-suggestion {
    color: var(--color-gray-big-stone);
    font-size: 16px;
}

.menu-scheme-sinistrom .tt-suggestion p {
    margin: 0 0 4px;
}
.menu-scheme-sinistrom .tt-suggestion.tt-cursor p,
.menu-scheme-sinistrom .tt-suggestion.tt-cursor,
.menu-scheme-sinistrom .tt-suggestion.tt-cursor a {
    background-color: var(--color-blue-smalt-blue);
    color: var(--color-white);
}

.menu-scheme-sinistrom .tt-suggestion.tt-cursor i {
    color: var(--color-white);
}

.menu-scheme-sinistrom .globalTypeAheadEntitySuggest {
    font-size: unset;
}

.menu-scheme-sinistrom .audience-menu-item {
    position: relative;
    font-size: 18px;
    font-weight: var(--normal-weight);
}

.menu-scheme-sinistrom .search-icon {
    margin-right: 20px;
    /* fix alignment as there is space around the icon */
    margin-top: -4px;
}

/************
* End Search
************/

/************
* Breadcrumb
************/
.menu-scheme-sinistrom .main-breadcrumb {    
    font-weight: var(--normal-weight);
    padding: 4px 10px 0px 0;
}

.menu-scheme-sinistrom .main-breadcrumb .breadcrumb-parent {
    color: var(--neutrals-n8);
    font-size: 12px;
    text-transform: uppercase;
}

.menu-scheme-sinistrom .main-breadcrumb .breadcrumb-description {
    color: var(--neutrals-n10);
    display: flex;
    max-width: 50vw;
    min-width: 0;
    font-size: 16px;
}

/* Keep the possible dropdown full width */
.menu-scheme-sinistrom .main-breadcrumb .breadcrumb-description .select {
    width: 50vw;
}

.menu-scheme-sinistrom .main-breadcrumb .breadcrumb-description .breadcrumb-changer {
    display: none;
    margin-top: 6px;
}

.menu-scheme-sinistrom .main-breadcrumb .breadcrumb-description:hover .breadcrumb-changer {
    display: block;
}

.menu-scheme-sinistrom .main-breadcrumb .breadcrumb-description .breadcrumb-description__content:before {
    float: right;
    content: attr(data-tail);
}

.menu-scheme-sinistrom .main-breadcrumb .breadcrumb-description .breadcrumb-description__content {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.menu-scheme-sinistrom .breadcrumb-info,
.menu-scheme-sinistrom .breadcrumb-changer {
    color: var(--neutrals-n7);
    font-size: 13px;
}


/************
* End Breadcrumb
************/

.menu-scheme-sinistrom .nav-line {
    display: grid;
    grid-template-columns: 1fr max-content max-content;
    gap: 8px;
    align-items: center;
    margin-top: var(--top-page-margin);
    padding: 0 var(--gutter-width);
    border-bottom: 1px solid var(--neutrals-n3);
}

html:not([data-scroll='0']) .menu-scheme-sinistrom .nav-line {
    background-color: var(--neutrals-white);
    border-bottom: none;
    box-shadow: 0 0 8px var(--neutrals-n3);
    margin-top: 0;
    /* makes sure the space from the top is the same as when not scrolling */
    padding-top: 4px;
    position: fixed;
    right: 0;
    top: 0;
    left: var(--leftNavWidth);
}

.menu-scheme-sinistrom .content-container {
    padding-left: var(--gutter-width);
    padding-right: var(--gutter-width);
    padding-top: var(--top-page-margin);
}

.menu-scheme-sinistrom .lotacon.breadcrumb-info {
    font-size: 15px;
    top: 5px;
    padding-left: 3px;
}

.menu-scheme-sinistrom .page-title {
    display: none;
}

@media (max-width: 1200px) {
    /* Bootstrap grid is wrapping so shrink down the gutter */
    .menu-scheme-sinistrom {
        --gutter-width: 15px;
    }
}

.page-nav .simplebar-scrollbar:before {
    background-color: var(--primary-p3);
}