.ui-datepicker-trigger{
   background-image: url("/web/images/flat/Calendar-Date-04-16.png");
   width: 16px;
   height: 15px;
   border: 0;
   /*margin-left:0.5em;*/
   cursor: pointer;
}

#loading {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: fixed;
  display: block;
  opacity:1;
  background-color: #fff;
  z-index: 100;
  text-align: center;
}

#loading-image {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  width: 40px;
  height: 40px;
}
#tabs-overview{
    min-height: 200px;
    border: none;
    padding: 0;
}
#tabs-overlap{
    min-height: 200px;
    border: none;
    padding: 0;
}
#tabs-optimize{
    display: none;
    min-height: 200px;
    border: none;
    padding: 0;
}

#chartDiv{
    text-align: center;
    margin-bottom: 10px;
    font-size:13px;
}

#chartOverlapDiv{
    text-align: center;
    margin-bottom: 10px;
    font-size:13px;
}

.ui-spinner .ui-button-text{
    padding:0;
}

.tooltip-inner {
    white-space:pre-wrap;
    text-align: left !important;
}
.tooltip-inner a,
.tooltip-inner a:visited {
    color: skyblue;
}
.tooltip-inner a:active {
    color: #ffff00;
}

/*Adpool*/
.ui-spinner {
    display: inline-block;
    overflow: hidden;
    padding: 0;
    position: relative;
    vertical-align: middle;
}

.ui-spinner-input {
    background: none repeat scroll 0 0 transparent;
    border: medium none;
    margin: 0.2em 22px 0.2em 0.4em;
    padding: 0;
    vertical-align: middle;
}
.ui-spinner a.ui-spinner-button {
    border-bottom: medium none;
    border-right: medium none;
    border-top: medium none;
}

.ui-spinner-up {
    top: 0;
}
.ui-spinner-button {
    cursor: default;
    display: block;
    font-size: 0.5em;
    height: 50%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: center;
    width: 16px;
}


.ui-spinner .ui-button-text {
    padding: 0;
}


.ui-spinner .ui-icon {
    left: 0;
    margin-top: -8px;
    position: absolute;
    top: 50%;
}

.ui-spinner a.ui-spinner-button {
    border-bottom: medium none;
    border-right: medium none;
    border-top: medium none;
}

.ui-spinner .ui-icon-triangle-1-s {
    background-position: -65px -16px;
}
.ui-icon-triangle-1-s {
    background-position: -64px -16px;
}
.ui-icon {
    background-repeat: no-repeat;
    display: block;
    overflow: hidden;
    text-indent: -99999px;
}
.ui-state-default .ui-icon {
    background-image: url("/web/images/jquery-ui/ui-icons_888888_256x240.png");
}
.ui-spinner-down {
    bottom: 0;
}

.widget-grid.max {
    margin: 0 10px;
}

/* Behaviors, and Audiences Detail Modal, may be referenced elsewhere */
.details_list {
    text-align: left;
    margin-top: 20px;
}
.details_list dt {
    font-size: 10pt;
    float: left;
    font-weight: bold;
}
.details_list dd {
    margin-left: 150px;
    padding-bottom: 15px;
}
.details_list dl {
    font-size: 10pt;
    overflow: hidden;
}

ul.alt-rows li.even {
    background-color: var(--color-white-smoke);
}
ul.path li {
    padding: 2px 0;
}

/* Generic Detail Modal, used for Rule Builder but better approach than the above dt,dd thing moving forwrd */
.info-listing .details-label {
    font-weight: bold;
    text-align: right;
}
.info-listing .details-value {
    font-weight: normal;
    word-wrap: break-word;
}

/* small inline loading indicator we can use elsewhere */
.small_load_indicator {
    font-size: 11pt;
    color: #555;
}
.small_load_indicator .icon.loading {
    background-image: url("/web/images/loading_148x148.gif");
    background-size: 30px 30px;
    height: 30px;
    width: 30px;

}

/* end of detail modal */


#referential-tag .tag_pixel { font-size: 10pt; }

/* bad place for these. quick fix for breaking URLs (web dir) */
.chzn-container-single .chzn-search input {
    background: url("/web/images/chosen-sprite.png") no-repeat scroll 100% -22px,
    -moz-linear-gradient(center top , #EEEEEE 1%, #FFFFFF 15%) repeat scroll 0 0 transparent !important;
}
.chzn-container-single .chzn-single abbr {
  background: url('/web/images/chosen-sprite.png') right top no-repeat !important;
}
.chzn-container-single .chzn-single div b {
    background: url('/web/images/chosen-sprite.png') no-repeat 0 0 transparent !important;
}
/* used on any line chart tooltip. May generalize further */
.graph_tooltip {
    border-radius: 5px;
    border: 1px solid #ADD9ED;
    position: absolute;
    z-index: 10;
    font-size: 11px;
    background-color: #E5F6FE;
    padding: 5px;
    color: #222;
    visibility: hidden;
}
.padded_portlet { margin: 0 30px; }

/* these are general for any "pseudomodal" */
div.pseudomodal #dialog-close {
    background: url("/web/images/icons/close-button.png") no-repeat scroll left top transparent;
    color: #3886B8;
    cursor: pointer;
    display: inline;
    outline: medium none;
    height: 24px;
    width: 24px;
    opacity: 0.6;
    position: absolute;
    right: 0px;
    top: 0px;
    text-decoration: none;
    z-index: 1103;
}
div.pseudomodal div.ui-dialog-titlebar { display: none; }
/*
div.ui-dialog.pseudomodal { box-shadow: 0 0 7px #999999; }
*/

.pseudomodal-close {
    background: url("/web/images/icons/close-button.png") no-repeat scroll left top transparent;
    color: #3886B8;
    cursor: pointer;
    display: inline;
    outline: medium none;
    height: 24px;
    width: 24px;
    opacity: 0.6;
    position: absolute;
    right: -12px;
    top: -14px;
    text-decoration: none;
    z-index: 1103;
}
.pseudomodal-close:hover {
    opacity: 1;
}
div.pseudomodal div.ui-dialog-titlebar {
  display: none;
}
div.ui-dialog.pseudomodal {
    line-height: 25px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0 0 5px #AAAAAA;
    overflow: visible;
}
div.ui-dialog.pseudomodal .ui-dialog-buttonpane {
    background: none;
}


div.loadingGraphic.selector {
    display: inline-block;
}

/* generic replacement for the lotame atom spinner, 16px version */
.tiny_loader {
    background: url("/web/images/loading_16x16.gif") no-repeat scroll left top transparent;
    display: inline-block;
    height: 16px;
    width: 16px;
    margin: 0;
    vertical-align: middle;
}
