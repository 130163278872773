/*
Auto-generated
Some of these icons are from FontAwesome and Glyphicons
 */
@font-face {
    font-family: "lotacon";
    src: url("font/lotacons/lotacon.eot?3ade2a088a85e8a74ae86634f8479c75?#iefix") format("embedded-opentype"),
url("font/lotacons/lotacon.woff2?3ade2a088a85e8a74ae86634f8479c75") format("woff2"),
url("font/lotacons/lotacon.woff?3ade2a088a85e8a74ae86634f8479c75") format("woff"),
url("font/lotacons/lotacon.ttf?3ade2a088a85e8a74ae86634f8479c75") format("truetype"),
url("font/lotacons/lotacon.svg?3ade2a088a85e8a74ae86634f8479c75#lotacon") format("svg");
    font-weight: normal;
    font-style: normal;
}

.lotacon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'lotacon' !important;
    speak: none;
    line-height: 1;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 1.4em;
    width: 1.1em;
    top: 2px;

    position: relative;
    display: inline-block;
    cursor: pointer;
    vertical-align: text-bottom;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^=".lotacon-"]:before {
    color: #565656;
}
.lotacon-noclick {
    cursor: default;
}

.lotacon-sm {
    font-size: 1em;
    width: 1em;
    top: 0px;
}

.lotacon-lg {
    font-size: 1.5em;
    width: 1.2em;
}

.lotacon.disabled {
    color: #dfdfdf;
}

.lotacon-white-overlay:after {
    color: #FFF;
}

.lotacon-white:before {
    color: #FFF;
}

.lotacon-stacked {
    box-sizing: border-box;
    padding: 0.143em;
    width: 1.45788em;
    height: 1.45788em;
}

.lotacon-stacked:after,
.lotacon-stacked:before {
    position: absolute;
    width: 1.45788em;
    height: 1.45788em;
    bottom: 0;
    left: 0;
    text-align:center;
}

.lotacon-stacked-overlay-sm:after {
    font-size: 0.5em;
    left: 25%;
    bottom: 30%;
}

.lotacon-1st-party-outline:before {
    content: "\f101";
}
.lotacon-1st-party-outline-overlay:after {
    content: "\f101";
}
.lotacon-1st-party:before {
    content: "\f102";
}
.lotacon-1st-party-overlay:after {
    content: "\f102";
}
.lotacon-address-card-o:before {
    content: "\f103";
}
.lotacon-address-card-o-overlay:after {
    content: "\f103";
}
.lotacon-analyze:before {
    content: "\f104";
}
.lotacon-analyze-overlay:after {
    content: "\f104";
}
.lotacon-android:before {
    content: "\f105";
}
.lotacon-android-overlay:after {
    content: "\f105";
}
.lotacon-anti-fraud-outline:before {
    content: "\f106";
}
.lotacon-anti-fraud-outline-overlay:after {
    content: "\f106";
}
.lotacon-anti-fraud:before {
    content: "\f107";
}
.lotacon-anti-fraud-overlay:after {
    content: "\f107";
}
.lotacon-apple:before {
    content: "\f108";
}
.lotacon-apple-overlay:after {
    content: "\f108";
}
.lotacon-arrow-down:before {
    content: "\f109";
}
.lotacon-arrow-down-overlay:after {
    content: "\f109";
}
.lotacon-arrow-go-back-line:before {
    content: "\f10a";
}
.lotacon-arrow-go-back-line-overlay:after {
    content: "\f10a";
}
.lotacon-arrow-left:before {
    content: "\f10b";
}
.lotacon-arrow-left-overlay:after {
    content: "\f10b";
}
.lotacon-arrow-right-ys:before {
    content: "\f10c";
}
.lotacon-arrow-right-ys-overlay:after {
    content: "\f10c";
}
.lotacon-arrow-right:before {
    content: "\f10d";
}
.lotacon-arrow-right-overlay:after {
    content: "\f10d";
}
.lotacon-arrow-up:before {
    content: "\f10e";
}
.lotacon-arrow-up-overlay:after {
    content: "\f10e";
}
.lotacon-audience:before {
    content: "\f10f";
}
.lotacon-audience-overlay:after {
    content: "\f10f";
}
.lotacon-ban-circle:before {
    content: "\f110";
}
.lotacon-ban-circle-overlay:after {
    content: "\f110";
}
.lotacon-bar-chart-line:before {
    content: "\f111";
}
.lotacon-bar-chart-line-overlay:after {
    content: "\f111";
}
.lotacon-bar-chart:before {
    content: "\f112";
}
.lotacon-bar-chart-overlay:after {
    content: "\f112";
}
.lotacon-bell:before {
    content: "\f113";
}
.lotacon-bell-overlay:after {
    content: "\f113";
}
.lotacon-benchmark-report:before {
    content: "\f114";
}
.lotacon-benchmark-report-overlay:after {
    content: "\f114";
}
.lotacon-bitcoin:before {
    content: "\f115";
}
.lotacon-bitcoin-overlay:after {
    content: "\f115";
}
.lotacon-book:before {
    content: "\f116";
}
.lotacon-book-overlay:after {
    content: "\f116";
}
.lotacon-bookmark:before {
    content: "\f117";
}
.lotacon-bookmark-overlay:after {
    content: "\f117";
}
.lotacon-branded-outline:before {
    content: "\f118";
}
.lotacon-branded-outline-overlay:after {
    content: "\f118";
}
.lotacon-branded:before {
    content: "\f119";
}
.lotacon-branded-overlay:after {
    content: "\f119";
}
.lotacon-briefcase:before {
    content: "\f11a";
}
.lotacon-briefcase-overlay:after {
    content: "\f11a";
}
.lotacon-calendar:before {
    content: "\f11b";
}
.lotacon-calendar-overlay:after {
    content: "\f11b";
}
.lotacon-check-line:before {
    content: "\f11c";
}
.lotacon-check-line-overlay:after {
    content: "\f11c";
}
.lotacon-check-square:before {
    content: "\f11d";
}
.lotacon-check-square-overlay:after {
    content: "\f11d";
}
.lotacon-check-ys:before {
    content: "\f11e";
}
.lotacon-check-ys-overlay:after {
    content: "\f11e";
}
.lotacon-check:before {
    content: "\f11f";
}
.lotacon-check-overlay:after {
    content: "\f11f";
}
.lotacon-chevron-down:before {
    content: "\f120";
}
.lotacon-chevron-down-overlay:after {
    content: "\f120";
}
.lotacon-chevron-left:before {
    content: "\f121";
}
.lotacon-chevron-left-overlay:after {
    content: "\f121";
}
.lotacon-chevron-right:before {
    content: "\f122";
}
.lotacon-chevron-right-overlay:after {
    content: "\f122";
}
.lotacon-chevron-up:before {
    content: "\f123";
}
.lotacon-chevron-up-overlay:after {
    content: "\f123";
}
.lotacon-circle-arrow-down:before {
    content: "\f124";
}
.lotacon-circle-arrow-down-overlay:after {
    content: "\f124";
}
.lotacon-circle-arrow-left:before {
    content: "\f125";
}
.lotacon-circle-arrow-left-overlay:after {
    content: "\f125";
}
.lotacon-circle-arrow-right:before {
    content: "\f126";
}
.lotacon-circle-arrow-right-overlay:after {
    content: "\f126";
}
.lotacon-circle-arrow-up:before {
    content: "\f127";
}
.lotacon-circle-arrow-up-overlay:after {
    content: "\f127";
}
.lotacon-circle-down:before {
    content: "\f128";
}
.lotacon-circle-down-overlay:after {
    content: "\f128";
}
.lotacon-circle-empty:before {
    content: "\f129";
}
.lotacon-circle-empty-overlay:after {
    content: "\f129";
}
.lotacon-circle-filled:before {
    content: "\f12a";
}
.lotacon-circle-filled-overlay:after {
    content: "\f12a";
}
.lotacon-circle-info:before {
    content: "\f12b";
}
.lotacon-circle-info-overlay:after {
    content: "\f12b";
}
.lotacon-circle-minus:before {
    content: "\f12c";
}
.lotacon-circle-minus-overlay:after {
    content: "\f12c";
}
.lotacon-circle-plus:before {
    content: "\f12d";
}
.lotacon-circle-plus-overlay:after {
    content: "\f12d";
}
.lotacon-circle-up:before {
    content: "\f12e";
}
.lotacon-circle-up-overlay:after {
    content: "\f12e";
}
.lotacon-clipboard:before {
    content: "\f12f";
}
.lotacon-clipboard-overlay:after {
    content: "\f12f";
}
.lotacon-clock:before {
    content: "\f130";
}
.lotacon-clock-overlay:after {
    content: "\f130";
}
.lotacon-close-line:before {
    content: "\f131";
}
.lotacon-close-line-overlay:after {
    content: "\f131";
}
.lotacon-cloud-download:before {
    content: "\f132";
}
.lotacon-cloud-download-overlay:after {
    content: "\f132";
}
.lotacon-cloud-line:before {
    content: "\f133";
}
.lotacon-cloud-line-overlay:after {
    content: "\f133";
}
.lotacon-cloud-upload:before {
    content: "\f134";
}
.lotacon-cloud-upload-overlay:after {
    content: "\f134";
}
.lotacon-code-line:before {
    content: "\f135";
}
.lotacon-code-line-overlay:after {
    content: "\f135";
}
.lotacon-cog:before {
    content: "\f136";
}
.lotacon-cog-overlay:after {
    content: "\f136";
}
.lotacon-collaborate-outline:before {
    content: "\f137";
}
.lotacon-collaborate-outline-overlay:after {
    content: "\f137";
}
.lotacon-collaborate:before {
    content: "\f138";
}
.lotacon-collaborate-overlay:after {
    content: "\f138";
}
.lotacon-comment:before {
    content: "\f139";
}
.lotacon-comment-overlay:after {
    content: "\f139";
}
.lotacon-countries:before {
    content: "\f13a";
}
.lotacon-countries-overlay:after {
    content: "\f13a";
}
.lotacon-credit-card:before {
    content: "\f13b";
}
.lotacon-credit-card-overlay:after {
    content: "\f13b";
}
.lotacon-dashboard:before {
    content: "\f13c";
}
.lotacon-dashboard-overlay:after {
    content: "\f13c";
}
.lotacon-default-logo:before {
    content: "\f13d";
}
.lotacon-default-logo-overlay:after {
    content: "\f13d";
}
.lotacon-delete-bin-line:before {
    content: "\f13e";
}
.lotacon-delete-bin-line-overlay:after {
    content: "\f13e";
}
.lotacon-discovery-dashboard:before {
    content: "\f13f";
}
.lotacon-discovery-dashboard-overlay:after {
    content: "\f13f";
}
.lotacon-dmas:before {
    content: "\f140";
}
.lotacon-dmas-overlay:after {
    content: "\f140";
}
.lotacon-dollar-off:before {
    content: "\f141";
}
.lotacon-dollar-off-overlay:after {
    content: "\f141";
}
.lotacon-dollar:before {
    content: "\f142";
}
.lotacon-dollar-overlay:after {
    content: "\f142";
}
.lotacon-dot-off:before {
    content: "\f143";
}
.lotacon-dot-off-overlay:after {
    content: "\f143";
}
.lotacon-dot-on:before {
    content: "\f144";
}
.lotacon-dot-on-overlay:after {
    content: "\f144";
}
.lotacon-download-alt:before {
    content: "\f145";
}
.lotacon-download-alt-overlay:after {
    content: "\f145";
}
.lotacon-download-line:before {
    content: "\f146";
}
.lotacon-download-line-overlay:after {
    content: "\f146";
}
.lotacon-download:before {
    content: "\f147";
}
.lotacon-download-overlay:after {
    content: "\f147";
}
.lotacon-drag-move-line:before {
    content: "\f148";
}
.lotacon-drag-move-line-overlay:after {
    content: "\f148";
}
.lotacon-draggable:before {
    content: "\f149";
}
.lotacon-draggable-overlay:after {
    content: "\f149";
}
.lotacon-drop-line:before {
    content: "\f14a";
}
.lotacon-drop-line-overlay:after {
    content: "\f14a";
}
.lotacon-duplicate:before {
    content: "\f14b";
}
.lotacon-duplicate-overlay:after {
    content: "\f14b";
}
.lotacon-edit:before {
    content: "\f14c";
}
.lotacon-edit-overlay:after {
    content: "\f14c";
}
.lotacon-eject:before {
    content: "\f14d";
}
.lotacon-eject-overlay:after {
    content: "\f14d";
}
.lotacon-embed:before {
    content: "\f14e";
}
.lotacon-embed-overlay:after {
    content: "\f14e";
}
.lotacon-envelope:before {
    content: "\f14f";
}
.lotacon-envelope-overlay:after {
    content: "\f14f";
}
.lotacon-equalizer-horizontal:before {
    content: "\f150";
}
.lotacon-equalizer-horizontal-overlay:after {
    content: "\f150";
}
.lotacon-error-warning-line:before {
    content: "\f151";
}
.lotacon-error-warning-line-overlay:after {
    content: "\f151";
}
.lotacon-euro-sign:before {
    content: "\f152";
}
.lotacon-euro-sign-overlay:after {
    content: "\f152";
}
.lotacon-euro:before {
    content: "\f153";
}
.lotacon-euro-overlay:after {
    content: "\f153";
}
.lotacon-exclamation-sign:before {
    content: "\f154";
}
.lotacon-exclamation-sign-overlay:after {
    content: "\f154";
}
.lotacon-expand-up-down-line:before {
    content: "\f155";
}
.lotacon-expand-up-down-line-overlay:after {
    content: "\f155";
}
.lotacon-export-blue:before {
    content: "\f156";
}
.lotacon-export-blue-overlay:after {
    content: "\f156";
}
.lotacon-export:before {
    content: "\f157";
}
.lotacon-export-overlay:after {
    content: "\f157";
}
.lotacon-eye-closed:before {
    content: "\f158";
}
.lotacon-eye-closed-overlay:after {
    content: "\f158";
}
.lotacon-eye-line:before {
    content: "\f159";
}
.lotacon-eye-line-overlay:after {
    content: "\f159";
}
.lotacon-eye-off-line:before {
    content: "\f15a";
}
.lotacon-eye-off-line-overlay:after {
    content: "\f15a";
}
.lotacon-eye-open:before {
    content: "\f15b";
}
.lotacon-eye-open-overlay:after {
    content: "\f15b";
}
.lotacon-file-copy-line:before {
    content: "\f15c";
}
.lotacon-file-copy-line-overlay:after {
    content: "\f15c";
}
.lotacon-file:before {
    content: "\f15d";
}
.lotacon-file-overlay:after {
    content: "\f15d";
}
.lotacon-filter-line:before {
    content: "\f15e";
}
.lotacon-filter-line-overlay:after {
    content: "\f15e";
}
.lotacon-filter:before {
    content: "\f15f";
}
.lotacon-filter-overlay:after {
    content: "\f15f";
}
.lotacon-flag:before {
    content: "\f160";
}
.lotacon-flag-overlay:after {
    content: "\f160";
}
.lotacon-flash:before {
    content: "\f161";
}
.lotacon-flash-overlay:after {
    content: "\f161";
}
.lotacon-flip:before {
    content: "\f162";
}
.lotacon-flip-overlay:after {
    content: "\f162";
}
.lotacon-floppy-disk:before {
    content: "\f163";
}
.lotacon-floppy-disk-overlay:after {
    content: "\f163";
}
.lotacon-floppy-open:before {
    content: "\f164";
}
.lotacon-floppy-open-overlay:after {
    content: "\f164";
}
.lotacon-floppy-remove:before {
    content: "\f165";
}
.lotacon-floppy-remove-overlay:after {
    content: "\f165";
}
.lotacon-floppy-save:before {
    content: "\f166";
}
.lotacon-floppy-save-overlay:after {
    content: "\f166";
}
.lotacon-floppy-saved:before {
    content: "\f167";
}
.lotacon-floppy-saved-overlay:after {
    content: "\f167";
}
.lotacon-folder-2-line:before {
    content: "\f168";
}
.lotacon-folder-2-line-overlay:after {
    content: "\f168";
}
.lotacon-folder-close:before {
    content: "\f169";
}
.lotacon-folder-close-overlay:after {
    content: "\f169";
}
.lotacon-folder-open:before {
    content: "\f16a";
}
.lotacon-folder-open-overlay:after {
    content: "\f16a";
}
.lotacon-forbid-line:before {
    content: "\f16b";
}
.lotacon-forbid-line-overlay:after {
    content: "\f16b";
}
.lotacon-fullscreen-line:before {
    content: "\f16c";
}
.lotacon-fullscreen-line-overlay:after {
    content: "\f16c";
}
.lotacon-fullscreen:before {
    content: "\f16d";
}
.lotacon-fullscreen-overlay:after {
    content: "\f16d";
}
.lotacon-gbp:before {
    content: "\f16e";
}
.lotacon-gbp-overlay:after {
    content: "\f16e";
}
.lotacon-geo-2:before {
    content: "\f16f";
}
.lotacon-geo-2-overlay:after {
    content: "\f16f";
}
.lotacon-geo-3:before {
    content: "\f170";
}
.lotacon-geo-3-overlay:after {
    content: "\f170";
}
.lotacon-geo-outline:before {
    content: "\f171";
}
.lotacon-geo-outline-overlay:after {
    content: "\f171";
}
.lotacon-geo:before {
    content: "\f172";
}
.lotacon-geo-overlay:after {
    content: "\f172";
}
.lotacon-hdd:before {
    content: "\f173";
}
.lotacon-hdd-overlay:after {
    content: "\f173";
}
.lotacon-history-line:before {
    content: "\f174";
}
.lotacon-history-line-overlay:after {
    content: "\f174";
}
.lotacon-home:before {
    content: "\f175";
}
.lotacon-home-overlay:after {
    content: "\f175";
}
.lotacon-horizontal-overflow:before {
    content: "\f176";
}
.lotacon-horizontal-overflow-overlay:after {
    content: "\f176";
}
.lotacon-icons8-folder:before {
    content: "\f177";
}
.lotacon-icons8-folder-overlay:after {
    content: "\f177";
}
.lotacon-import:before {
    content: "\f178";
}
.lotacon-import-overlay:after {
    content: "\f178";
}
.lotacon-inbox:before {
    content: "\f179";
}
.lotacon-inbox-overlay:after {
    content: "\f179";
}
.lotacon-info-sign:before {
    content: "\f17a";
}
.lotacon-info-sign-overlay:after {
    content: "\f17a";
}
.lotacon-key:before {
    content: "\f17b";
}
.lotacon-key-overlay:after {
    content: "\f17b";
}
.lotacon-krw:before {
    content: "\f17c";
}
.lotacon-krw-overlay:after {
    content: "\f17c";
}
.lotacon-ldx-precision-outline:before {
    content: "\f17d";
}
.lotacon-ldx-precision-outline-overlay:after {
    content: "\f17d";
}
.lotacon-ldx-precision:before {
    content: "\f17e";
}
.lotacon-ldx-precision-overlay:after {
    content: "\f17e";
}
.lotacon-ldx-scale-outline:before {
    content: "\f17f";
}
.lotacon-ldx-scale-outline-overlay:after {
    content: "\f17f";
}
.lotacon-ldx-scale:before {
    content: "\f180";
}
.lotacon-ldx-scale-overlay:after {
    content: "\f180";
}
.lotacon-light_bulb2:before {
    content: "\f181";
}
.lotacon-light_bulb2-overlay:after {
    content: "\f181";
}
.lotacon-link:before {
    content: "\f182";
}
.lotacon-link-overlay:after {
    content: "\f182";
}
.lotacon-links-line:before {
    content: "\f183";
}
.lotacon-links-line-overlay:after {
    content: "\f183";
}
.lotacon-list-alt:before {
    content: "\f184";
}
.lotacon-list-alt-overlay:after {
    content: "\f184";
}
.lotacon-lock:before {
    content: "\f185";
}
.lotacon-lock-overlay:after {
    content: "\f185";
}
.lotacon-log-out:before {
    content: "\f186";
}
.lotacon-log-out-overlay:after {
    content: "\f186";
}
.lotacon-magic:before {
    content: "\f187";
}
.lotacon-magic-overlay:after {
    content: "\f187";
}
.lotacon-magnet:before {
    content: "\f188";
}
.lotacon-magnet-overlay:after {
    content: "\f188";
}
.lotacon-mail-line:before {
    content: "\f189";
}
.lotacon-mail-line-overlay:after {
    content: "\f189";
}
.lotacon-mail-open-line:before {
    content: "\f18a";
}
.lotacon-mail-open-line-overlay:after {
    content: "\f18a";
}
.lotacon-mail-reply:before {
    content: "\f18b";
}
.lotacon-mail-reply-overlay:after {
    content: "\f18b";
}
.lotacon-menu-analytics:before {
    content: "\f18c";
}
.lotacon-menu-analytics-overlay:after {
    content: "\f18c";
}
.lotacon-menu-audiences:before {
    content: "\f18d";
}
.lotacon-menu-audiences-overlay:after {
    content: "\f18d";
}
.lotacon-menu-collaborate:before {
    content: "\f18e";
}
.lotacon-menu-collaborate-overlay:after {
    content: "\f18e";
}
.lotacon-menu-connections:before {
    content: "\f18f";
}
.lotacon-menu-connections-overlay:after {
    content: "\f18f";
}
.lotacon-menu-models:before {
    content: "\f190";
}
.lotacon-menu-models-overlay:after {
    content: "\f190";
}
.lotacon-menu-my-data:before {
    content: "\f191";
}
.lotacon-menu-my-data-overlay:after {
    content: "\f191";
}
.lotacon-menu-reporting:before {
    content: "\f192";
}
.lotacon-menu-reporting-overlay:after {
    content: "\f192";
}
.lotacon-minus-sign:before {
    content: "\f193";
}
.lotacon-minus-sign-overlay:after {
    content: "\f193";
}
.lotacon-minus:before {
    content: "\f194";
}
.lotacon-minus-overlay:after {
    content: "\f194";
}
.lotacon-more-options:before {
    content: "\f195";
}
.lotacon-more-options-overlay:after {
    content: "\f195";
}
.lotacon-more-vert:before {
    content: "\f196";
}
.lotacon-more-vert-overlay:after {
    content: "\f196";
}
.lotacon-move:before {
    content: "\f197";
}
.lotacon-move-overlay:after {
    content: "\f197";
}
.lotacon-new-window:before {
    content: "\f198";
}
.lotacon-new-window-overlay:after {
    content: "\f198";
}
.lotacon-noodly-appendage:before {
    content: "\f199";
}
.lotacon-noodly-appendage-overlay:after {
    content: "\f199";
}
.lotacon-notification:before {
    content: "\f19a";
}
.lotacon-notification-overlay:after {
    content: "\f19a";
}
.lotacon-off:before {
    content: "\f19b";
}
.lotacon-off-overlay:after {
    content: "\f19b";
}
.lotacon-ok-circle:before {
    content: "\f19c";
}
.lotacon-ok-circle-overlay:after {
    content: "\f19c";
}
.lotacon-ok-sign:before {
    content: "\f19d";
}
.lotacon-ok-sign-overlay:after {
    content: "\f19d";
}
.lotacon-ok:before {
    content: "\f19e";
}
.lotacon-ok-overlay:after {
    content: "\f19e";
}
.lotacon-open:before {
    content: "\f19f";
}
.lotacon-open-overlay:after {
    content: "\f19f";
}
.lotacon-paperclip:before {
    content: "\f1a0";
}
.lotacon-paperclip-overlay:after {
    content: "\f1a0";
}
.lotacon-parent-line:before {
    content: "\f1a1";
}
.lotacon-parent-line-overlay:after {
    content: "\f1a1";
}
.lotacon-pause-line:before {
    content: "\f1a2";
}
.lotacon-pause-line-overlay:after {
    content: "\f1a2";
}
.lotacon-pause:before {
    content: "\f1a3";
}
.lotacon-pause-overlay:after {
    content: "\f1a3";
}
.lotacon-pencil-line:before {
    content: "\f1a4";
}
.lotacon-pencil-line-overlay:after {
    content: "\f1a4";
}
.lotacon-pencil:before {
    content: "\f1a5";
}
.lotacon-pencil-overlay:after {
    content: "\f1a5";
}
.lotacon-pencil2:before {
    content: "\f1a6";
}
.lotacon-pencil2-overlay:after {
    content: "\f1a6";
}
.lotacon-phone:before {
    content: "\f1a7";
}
.lotacon-phone-overlay:after {
    content: "\f1a7";
}
.lotacon-play-circle:before {
    content: "\f1a8";
}
.lotacon-play-circle-overlay:after {
    content: "\f1a8";
}
.lotacon-play-line:before {
    content: "\f1a9";
}
.lotacon-play-line-overlay:after {
    content: "\f1a9";
}
.lotacon-play:before {
    content: "\f1aa";
}
.lotacon-play-overlay:after {
    content: "\f1aa";
}
.lotacon-plus-large:before {
    content: "\f1ab";
}
.lotacon-plus-large-overlay:after {
    content: "\f1ab";
}
.lotacon-plus-sign:before {
    content: "\f1ac";
}
.lotacon-plus-sign-overlay:after {
    content: "\f1ac";
}
.lotacon-plus:before {
    content: "\f1ad";
}
.lotacon-plus-overlay:after {
    content: "\f1ad";
}
.lotacon-price-tag-3-line:before {
    content: "\f1ae";
}
.lotacon-price-tag-3-line-overlay:after {
    content: "\f1ae";
}
.lotacon-pushpin:before {
    content: "\f1af";
}
.lotacon-pushpin-overlay:after {
    content: "\f1af";
}
.lotacon-question-sign:before {
    content: "\f1b0";
}
.lotacon-question-sign-overlay:after {
    content: "\f1b0";
}
.lotacon-question:before {
    content: "\f1b1";
}
.lotacon-question-overlay:after {
    content: "\f1b1";
}
.lotacon-random:before {
    content: "\f1b2";
}
.lotacon-random-overlay:after {
    content: "\f1b2";
}
.lotacon-record:before {
    content: "\f1b3";
}
.lotacon-record-overlay:after {
    content: "\f1b3";
}
.lotacon-refresh:before {
    content: "\f1b4";
}
.lotacon-refresh-overlay:after {
    content: "\f1b4";
}
.lotacon-remove-circle:before {
    content: "\f1b5";
}
.lotacon-remove-circle-overlay:after {
    content: "\f1b5";
}
.lotacon-remove-sign:before {
    content: "\f1b6";
}
.lotacon-remove-sign-overlay:after {
    content: "\f1b6";
}
.lotacon-remove:before {
    content: "\f1b7";
}
.lotacon-remove-overlay:after {
    content: "\f1b7";
}
.lotacon-repeat:before {
    content: "\f1b8";
}
.lotacon-repeat-overlay:after {
    content: "\f1b8";
}
.lotacon-reply:before {
    content: "\f1b9";
}
.lotacon-reply-overlay:after {
    content: "\f1b9";
}
.lotacon-resize-full:before {
    content: "\f1ba";
}
.lotacon-resize-full-overlay:after {
    content: "\f1ba";
}
.lotacon-resize-horizontal:before {
    content: "\f1bb";
}
.lotacon-resize-horizontal-overlay:after {
    content: "\f1bb";
}
.lotacon-resize-small:before {
    content: "\f1bc";
}
.lotacon-resize-small-overlay:after {
    content: "\f1bc";
}
.lotacon-resize-vertical:before {
    content: "\f1bd";
}
.lotacon-resize-vertical-overlay:after {
    content: "\f1bd";
}
.lotacon-return-button:before {
    content: "\f1be";
}
.lotacon-return-button-overlay:after {
    content: "\f1be";
}
.lotacon-retweet:before {
    content: "\f1bf";
}
.lotacon-retweet-overlay:after {
    content: "\f1bf";
}
.lotacon-robot:before {
    content: "\f1c0";
}
.lotacon-robot-overlay:after {
    content: "\f1c0";
}
.lotacon-ruble:before {
    content: "\f1c1";
}
.lotacon-ruble-overlay:after {
    content: "\f1c1";
}
.lotacon-rupee:before {
    content: "\f1c2";
}
.lotacon-rupee-overlay:after {
    content: "\f1c2";
}
.lotacon-save:before {
    content: "\f1c3";
}
.lotacon-save-overlay:after {
    content: "\f1c3";
}
.lotacon-saved:before {
    content: "\f1c4";
}
.lotacon-saved-overlay:after {
    content: "\f1c4";
}
.lotacon-screenshot:before {
    content: "\f1c5";
}
.lotacon-screenshot-overlay:after {
    content: "\f1c5";
}
.lotacon-search:before {
    content: "\f1c6";
}
.lotacon-search-overlay:after {
    content: "\f1c6";
}
.lotacon-search_2:before {
    content: "\f1c7";
}
.lotacon-search_2-overlay:after {
    content: "\f1c7";
}
.lotacon-send-backward:before {
    content: "\f1c8";
}
.lotacon-send-backward-overlay:after {
    content: "\f1c8";
}
.lotacon-settings-2-line:before {
    content: "\f1c9";
}
.lotacon-settings-2-line-overlay:after {
    content: "\f1c9";
}
.lotacon-share-alt:before {
    content: "\f1ca";
}
.lotacon-share-alt-overlay:after {
    content: "\f1ca";
}
.lotacon-share-forward-line:before {
    content: "\f1cb";
}
.lotacon-share-forward-line-overlay:after {
    content: "\f1cb";
}
.lotacon-share:before {
    content: "\f1cc";
}
.lotacon-share-overlay:after {
    content: "\f1cc";
}
.lotacon-shopping-cart:before {
    content: "\f1cd";
}
.lotacon-shopping-cart-overlay:after {
    content: "\f1cd";
}
.lotacon-signal:before {
    content: "\f1ce";
}
.lotacon-signal-overlay:after {
    content: "\f1ce";
}
.lotacon-sitemap:before {
    content: "\f1cf";
}
.lotacon-sitemap-overlay:after {
    content: "\f1cf";
}
.lotacon-sort-by-alphabet-alt:before {
    content: "\f1d0";
}
.lotacon-sort-by-alphabet-alt-overlay:after {
    content: "\f1d0";
}
.lotacon-sort-by-alphabet:before {
    content: "\f1d1";
}
.lotacon-sort-by-alphabet-overlay:after {
    content: "\f1d1";
}
.lotacon-sort-by-attributes-alt:before {
    content: "\f1d2";
}
.lotacon-sort-by-attributes-alt-overlay:after {
    content: "\f1d2";
}
.lotacon-sort-by-attributes:before {
    content: "\f1d3";
}
.lotacon-sort-by-attributes-overlay:after {
    content: "\f1d3";
}
.lotacon-sort-by-order-alt:before {
    content: "\f1d4";
}
.lotacon-sort-by-order-alt-overlay:after {
    content: "\f1d4";
}
.lotacon-sort-by-order:before {
    content: "\f1d5";
}
.lotacon-sort-by-order-overlay:after {
    content: "\f1d5";
}
.lotacon-sort:before {
    content: "\f1d6";
}
.lotacon-sort-overlay:after {
    content: "\f1d6";
}
.lotacon-square-minus:before {
    content: "\f1d7";
}
.lotacon-square-minus-overlay:after {
    content: "\f1d7";
}
.lotacon-square:before {
    content: "\f1d8";
}
.lotacon-square-overlay:after {
    content: "\f1d8";
}
.lotacon-stack-line:before {
    content: "\f1d9";
}
.lotacon-stack-line-overlay:after {
    content: "\f1d9";
}
.lotacon-star-empty:before {
    content: "\f1da";
}
.lotacon-star-empty-overlay:after {
    content: "\f1da";
}
.lotacon-star:before {
    content: "\f1db";
}
.lotacon-star-overlay:after {
    content: "\f1db";
}
.lotacon-stats:before {
    content: "\f1dc";
}
.lotacon-stats-overlay:after {
    content: "\f1dc";
}
.lotacon-stop:before {
    content: "\f1dd";
}
.lotacon-stop-overlay:after {
    content: "\f1dd";
}
.lotacon-store-line:before {
    content: "\f1de";
}
.lotacon-store-line-overlay:after {
    content: "\f1de";
}
.lotacon-summary-dashboard:before {
    content: "\f1df";
}
.lotacon-summary-dashboard-overlay:after {
    content: "\f1df";
}
.lotacon-tag:before {
    content: "\f1e0";
}
.lotacon-tag-overlay:after {
    content: "\f1e0";
}
.lotacon-tags:before {
    content: "\f1e1";
}
.lotacon-tags-overlay:after {
    content: "\f1e1";
}
.lotacon-team-line:before {
    content: "\f1e2";
}
.lotacon-team-line-overlay:after {
    content: "\f1e2";
}
.lotacon-th-large:before {
    content: "\f1e3";
}
.lotacon-th-large-overlay:after {
    content: "\f1e3";
}
.lotacon-th-list:before {
    content: "\f1e4";
}
.lotacon-th-list-overlay:after {
    content: "\f1e4";
}
.lotacon-th:before {
    content: "\f1e5";
}
.lotacon-th-overlay:after {
    content: "\f1e5";
}
.lotacon-time-line:before {
    content: "\f1e6";
}
.lotacon-time-line-overlay:after {
    content: "\f1e6";
}
.lotacon-time:before {
    content: "\f1e7";
}
.lotacon-time-overlay:after {
    content: "\f1e7";
}
.lotacon-top-behaviors:before {
    content: "\f1e8";
}
.lotacon-top-behaviors-overlay:after {
    content: "\f1e8";
}
.lotacon-transfer:before {
    content: "\f1e9";
}
.lotacon-transfer-overlay:after {
    content: "\f1e9";
}
.lotacon-trash:before {
    content: "\f1ea";
}
.lotacon-trash-overlay:after {
    content: "\f1ea";
}
.lotacon-umbrella-line:before {
    content: "\f1eb";
}
.lotacon-umbrella-line-overlay:after {
    content: "\f1eb";
}
.lotacon-unchecked:before {
    content: "\f1ec";
}
.lotacon-unchecked-overlay:after {
    content: "\f1ec";
}
.lotacon-upload:before {
    content: "\f1ed";
}
.lotacon-upload-overlay:after {
    content: "\f1ed";
}
.lotacon-usd:before {
    content: "\f1ee";
}
.lotacon-usd-overlay:after {
    content: "\f1ee";
}
.lotacon-user-follow-line:before {
    content: "\f1ef";
}
.lotacon-user-follow-line-overlay:after {
    content: "\f1ef";
}
.lotacon-user:before {
    content: "\f1f0";
}
.lotacon-user-overlay:after {
    content: "\f1f0";
}
.lotacon-vip-crown:before {
    content: "\f1f1";
}
.lotacon-vip-crown-overlay:after {
    content: "\f1f1";
}
.lotacon-warning-sign-filled:before {
    content: "\f1f2";
}
.lotacon-warning-sign-filled-overlay:after {
    content: "\f1f2";
}
.lotacon-warning-sign:before {
    content: "\f1f3";
}
.lotacon-warning-sign-overlay:after {
    content: "\f1f3";
}
.lotacon-wrench:before {
    content: "\f1f4";
}
.lotacon-wrench-overlay:after {
    content: "\f1f4";
}
.lotacon-x:before {
    content: "\f1f5";
}
.lotacon-x-overlay:after {
    content: "\f1f5";
}
.lotacon-yen:before {
    content: "\f1f6";
}
.lotacon-yen-overlay:after {
    content: "\f1f6";
}
.lotacon-ys-arrow-left:before {
    content: "\f1f7";
}
.lotacon-ys-arrow-left-overlay:after {
    content: "\f1f7";
}
.lotacon-ys-external-link:before {
    content: "\f1f8";
}
.lotacon-ys-external-link-overlay:after {
    content: "\f1f8";
}
.lotacon-zoom-in:before {
    content: "\f1f9";
}
.lotacon-zoom-in-overlay:after {
    content: "\f1f9";
}
.lotacon-zoom-out:before {
    content: "\f1fa";
}
.lotacon-zoom-out-overlay:after {
    content: "\f1fa";
}
