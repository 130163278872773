.btn {
    --btn-border-radius: 2px;
    --btn-box-shadow: 1px 1px 2px rgba(136, 136, 136, 0.5);
    --btn-disabled-text-color: var(--color-gray-chateau);
}

.page-scheme-umbra .btn {
    --btn-border-radius: 3px;
    --btn-box-shadow: none;

    border-width: 1px;
    font-size: var(--brand-font-size-button);
    line-height: 16px;

    padding: 6px 15px;
    text-align: center;
}

.page-scheme-umbra .btn:hover {
    color: var(--btn-text-color);
    background-color: var(--btn-background-color);
}

.page-scheme-umbra .btn-xs {
    font-size: 11px;
    line-height: 1.5;
    padding: 1px 5px;
}

.page-scheme-umbra .btn-lg {
    font-size: 18px;
    padding: 3px 10px;
}

/* No Theme */

.btn {
    border-radius: var(--btn-border-radius);
    box-shadow: var(--btn-box-shadow);
}

.btn,
.btn:focus {
    color: var(--btn-text-color);
    background-color: var(--btn-background-color);
    border-color: var(--btn-border-color);
    margin-bottom: 1px;
}

.btn.btn-sm.toggle-on {
    padding-left: 6px;
}

/**
Disabled Buttons
**/
.btn[disabled],
.btn.disabled {
    border-color: var(--color-gray-chateau);
    color: var(--btn-disabled-text-color);
    opacity: 1;
}

.btn[disabled] .btn.toggle-on,
.btn.disabled,
.btn.disabled:hover,
.btn-primary.disabled:focus {
    background-color: var(--color-white);
    border-color: var(--color-gray-chateau);
    color: var(--color-gray-chateau);
    opacity: 1;
}
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus fieldset[disabled] .btn-warning.focus {
    background-color: var(--color-white);
    border-color: var(--color-gray-chateau);
    color: var(--color-gray-chateau);
}

.page-scheme-umbra .btn[disabled],
.page-scheme-umbra .btn[disabled] .btn.toggle-on,
.page-scheme-umbra .btn.disabled,
.page-scheme-umbra .btn.disabled:hover,
.page-scheme-umbra .btn-primary.disabled:focus {
    background-color: var(--btn-background-color);
    border-color: var(--btn-border-color);
    color: var(--btn-text-color);
    opacity: 0.5;
}
.page-scheme-umbra .multiselect.btn[disabled] {
    background-color: unset;
}

.toggle.btn-default {
    background-color: #adadad;
}
.toggle.btn.disabled .toggle-group .btn {
    pointer-events: initial;
}
.toggle.btn.disabled .toggle-group .toggle-handle {
    height: 100%;
    pointer-events: initial;
}

.btn[disabled] i {
    cursor: not-allowed;
}

/**
Primary
**/
.btn-primary {
    --btn-text-color: var(--color-primary);
    --btn-border-color: var(--color-primary);
    --btn-background-color: var(--color-white-smoke);
}

.btn-primary.toggle,
.toggle .btn-primary {
    --btn-text-color: var(--color-white);
    --btn-border-color: var(--color-primary);
    --btn-background-color: var(--color-primary);
}

.page-scheme-umbra .btn-primary,
.page-scheme-umbra .open > .dropdown-toggle.btn-primary {
    --btn-text-color: var(--color-white);
    --btn-border-color: var(--color-primary);
    --btn-background-color: var(--color-primary);
}

/**
Secondary
**/

.btn-blue,
.btn-secondary {
    --btn-background-color: var(--color-blue-robin-egg);
    --btn-border-color: var(--color-blue-robin-egg);
    --btn-text-color: var(--color-white);
}

.page-scheme-umbra .btn-secondary {
    --btn-text-color: var(--color-white);
    --btn-border-color: var(--color-secondary);
    --btn-background-color: var(--color-secondary);
}

/**
Tertiary
**/

.btn-gray,
.btn-tertiary,
.btn-default {
    --btn-background-color: var(--color-gray-chateau);
    --btn-border-color: var(--color-gray-chateau);
    --btn-text-color: var(--color-white);
}

.page-scheme-umbra .btn-tertiary,
.btn-tertiary {
    --btn-text-color: var(--color-gray-big-stone);
    --btn-border-color: var(--color-gray-big-stone);
    --btn-background-color: var(--color-tertiary);
}

.btn-link,
.btn-link:active,
#pageContainer .btn.btn-link:active {
    border: none;
    box-shadow: none;
    color: var(--color-gray-chateau);
}

.btn-icon,
.btn-icon:active,
#pageContainer .btn.btn-icon:active {
    border: none;
    box-shadow: none;
}

/**
Export Button
*/
.btn.btn-export {
    background: var(--color-white);
    border: 1px solid var(--fc-border-color);
    color: var(--color-gray-big-stone);
}

/**
Group Button 
**/
.page-scheme-umbra .btn-group > .btn {
    line-height: 16px;
    padding: 4px 8px;
}

.page-scheme-umbra .toggleGroup > .btn {
    margin-bottom: 3px;
}

.page-scheme-umbra .toggle.btn {
    min-height: 30px;
}

.page-scheme-umbra .btn-group > .primary-action-dropdown {
    padding-left: 8px;
    padding-right: 8px;
    border-left: #aaa solid 2px;
}

/* Align buttons with other fields */
.button-top-margin {
    margin-top: 22px;
}

.page-scheme-umbra .btn-group > .btn-icon {
    padding: 0;
}

/* Sinistrom */
.page-scheme-sinistrom .btn {
    --btn-border-radius: 4px;
    --btn-box-shadow: none;

    letter-spacing: 1.25px;
    font-weight: 500;
    line-height: 16px;
    outline: none;
    padding: 10px 17px;
}

.page-scheme-sinistrom .btn .lotacon {
    line-height: 16px;
    top: -1px;
    margin-right: 5px;
}

.page-scheme-sinistrom .btn:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    outline: none;
}

.page-scheme-sinistrom .btn:active {
    box-shadow: none;
}

.page-scheme-sinistrom .btn-primary,
.scheme-yellow-suit .btn-primary {
    --btn-text-color: var(--color-white);
    --btn-background-color: var(--color-primary);
}

.page-scheme-sinistrom .btn-primary.destructive {
    --btn-background-color: var(--color-danger);
    --btn-border-color: var(--color-danger);
}

.page-scheme-sinistrom .btn-primary.destructive:hover {
    background-color: var(--color-danger-lighter);
    border-color: var(--color-danger-lighter);
}

.page-scheme-sinistrom .btn-primary.destructive:active {
    background-color: var(--color-danger-darker);
    border-color: var(--color-danger-darker);
}

.page-scheme-sinistrom .btn-primary:hover,
.scheme-yellow-suit .btn-primary:hover {
    background-color: var(--color-primary-lighter);
    border-color: var(--color-primary-lighter);
    outline: none;
}

.page-scheme-sinistrom .btn-primary:active,
.scheme-yellow-suit .btn-primary:active {
    background-color: var(--color-primary-darker);
    --btn-border-color: var(--color-primary-darker);
    outline: none;
}

.page-scheme-sinistrom .btn-primary[disabled],
.page-scheme-sinistrom .btn-primary.disabled {
    --btn-background-color: var(--color-white-gainsboro);
    border-color: var(--btn-background-color);
}

.page-scheme-sinistrom .btn-secondary {
    --btn-background-color: var(--color-white);
    --btn-border-color: var(--color-primary);
    --btn-text-color: var(--color-primary);
}

.page-scheme-sinistrom .btn-secondary.destructive {
    --btn-border-color: var(--color-danger);
    --btn-text-color: var(--color-danger);
}

.page-scheme-sinistrom .btn-secondary.destructive:hover {
    border-color: var(--color-danger-lighter);
    color: var(--color-danger-lighter);
}

.page-scheme-sinistrom .btn-secondary.destructive:active {
    border-color: var(--color-danger-darker);
    color: var(--color-danger-darker);
}

.page-scheme-sinistrom .btn-secondary:hover {
    border-color: var(--color-primary-lighter);
    color: var(--color-primary-lighter);
    outline: none;
}

.page-scheme-sinistrom .btn-secondary:active {
    border-color: var(--color-primary-darker);
    color: var(--color-primary-darker);
    outline: none;
}

.page-scheme-sinistrom .btn-secondary[disabled] {
    --btn-background-color: var(--color-white);
}

.page-scheme-sinistrom .btn-tertiary {
    --btn-background-color: transparent;
    --btn-border-color: transparent;
    --btn-text-color: var(--color-primary);
    outline: none;
}
.page-scheme-sinistrom .btn-tertiary.destructive {
    --btn-text-color: var(--color-danger);
    --btn-border-color: var(--btn-background-color);
}

.page-scheme-sinistrom .btn-tertiary.destructive:hover {
    color: var(--color-danger-lighter);
}

.page-scheme-sinistrom .btn-tertiary.destructive:active {
    color: var(--color-danger-darker);
}

.page-scheme-sinistrom .btn-tertiary:hover {
    color: var(--color-primary-lighter);
    box-shadow: none;
    outline: none;
}

.page-scheme-sinistrom .btn-tertiary:active {
    color: var(--color-primary-darker);
    outline: none;
}

.page-scheme-sinistrom .btn-tertiary[disabled] {
    border-color: var(--btn-background-color);
}

.page-scheme-sinistrom .btn[disabled]:hover {
    background-color: var(--btn-background-color);
    color: var(--btn-disabled-text-color);
    box-shadow: none;
}

.btn-group .btn:focus {
    outline: none;
}

.btn-group .btn[data-active="true"]:hover,
.btn-group .btn[data-active="false"]:hover {
    background-color: var(--primary-p4);
    color: var(--primary-p1);
}

.btn-group .btn[data-active="true"],
.btn-group .btn[data-active="true"]:hover {
    background-color: var(--primary-p1);
    color: var(--primary-p4);
}