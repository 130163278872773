:root {
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 600;
}

[data-weight="regular"] {
    font-weight: var(--font-weight-regular);
}

[data-weight="medium"] {
    font-weight: var(--font-weight-medium);
}

[data-weight="semibold"] {
    font-weight: var(--font-weight-semibold);
}

[data-weight="bold"] {
    font-weight: var(--font-weight-bold);
}

[data-decoration="underline"] {
    text-decoration: underline;
}

[data-decoration="dotted"] {
    text-decoration: underline dotted;
}

[data-transform="upper"] {
    text-transform: uppercase;
}

[data-transform="lower"] {
    text-transform: lowercase;
}

.h36 {
    font-size: 36px;
    font-weight: var(--font-weight-semibold);
    line-height: 42px;
}

.h24 {
    font-size: 24px;
    line-height: 28px;
}

.h22 {
    font-size: 22px;
    line-height: 28px;
}

.h18 {
    font-size: 18px;
    line-height: 21px;
}


.h18[data-weight="semibold"] {
    line-height: 26px;
}

.h18[data-weight="medium"] {
    line-height: 21px;
}

.h16 {
    font-size: 16px;
    line-height: 19px;
}

.h16[data-weight="bold"] {
    line-height: 19px;
}

.h16[data-weight="medium"] {
    line-height: 19px;
}

.h16[data-decoration="tall"] {
    line-height: 155%;
}

.h14 {
    font-size: 14px;
    line-height: 155%;
}

.h14[data-weight="medium"] {
    line-height: 145%;
}

.h14[data-weight="semibold"] {
    line-height: 155%;
}

.h13 {
    font-size: 13px;
    line-height: 145%;
}

.h13[data-weight="medium"] {
    line-height: 145%;
}

.h12 {
    font-size: 12px;
    line-height: 145%;
}

.h12[data-weight="medium"] {
    line-height: 150%;
}

.h12[data-weight="semibold"] {
    line-height: 150%;
}

.h10 {
    font-size: 10px;
    line-height: 145%;
}

.h10[data-weight="medium"] {
    line-height: 150%;
}

.h10[data-weight="bold"] {
    line-height: 145%;
}