#user-notification-count,
#notifcation-count:hover {
    color: var(--neutrals-n3);
    text-decoration: none;
}

.circle-badge {
    --circle-size: 20px;
    width: var(--circle-size);
    height: var(--circle-size);
    line-height: var(--circle-size);
    background-color: var(--red-r3);
    border-radius: 50%;

    font-size: 8px;
    text-align: center;

    position: absolute;
    top: 4px;
    left: 15px;
}

#user-notification-count-container {
    padding-right: 20px;
}

#envelope_holder {
    display: inline-block;
    min-height: 13px;
}

#user-notification-count {
    display: inline-block;
    min-height: 20px;
    vertical-align: top;
    margin-top: 8px;
}

#notification-count .notification-icon {
    font-size: 20px;
    color: var(--neutrals-n7);
}

.app-admin #notification-count .notification-icon {
    color: var(--color-white);
}

.notification-menu.dropdown-menu {
    padding: 15px;
}

.notification-menu__summary {
    color: var(--color-gray-big-stone);
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 100px;
    padding-bottom: 15px;
}

.notification-menu__summary.-unread {
    font-weight: bold;
}

.notification-menu__title {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 10px;
    color: var(--color-gray-big-stone);
}

.notification-menu__header {
    font-size: 12px;
    color: var(--color-gray-chateau);
    padding: 15px 0;
}
