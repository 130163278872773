/**********************
* page-scheme-umbra
**********************/
:root {
    --grid-gap: 3px;
    --justify-items: start;
}

[data-gap="4"] {
    --grid-gap: 4px;
}

[data-gap="8"] {
    --grid-gap: 8px;
}

[data-gap="12"] {
    --grid-gap: 12px;
}

[data-gap="16"] {
    --grid-gap: 16px;
}

.col-auto-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, max-content));
}

.col-1-grid {
    display: grid;
    gap: var(--grid-gap);
    grid-template-columns: repeat(1, 1fr);
    justify-items: var(--justify-items);
}

.col-2-grid {
    display: grid;
    gap: var(--grid-gap);
    grid-template-columns: repeat(2, 1fr);
    justify-items: var(--justify-items);
}

.col-3-grid {
    display: grid;
    gap: var(--grid-gap);
    grid-template-columns: repeat(3, 1fr);
    justify-items: var(--justify-items);
}

.col-4-grid {
    display: grid;
    gap: var(--grid-gap);
    grid-template-columns: repeat(4, minmax(0, 1fr));
    justify-items: var(--justify-items);
    min-height: 0;
    min-width: 0;
}

.col-5-grid {
    display: grid;
    gap: var(--grid-gap);
    grid-template-columns: repeat(5, minmax(0, 1fr));
    justify-items: var(--justify-items);
    min-height: 0;
    min-width: 0;
}


.col-4-grid > .btn,
.col-5-grid > .btn {
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
}

/* End page-scheme-umbra */

.row-3-grid {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(13, 1fr);
    -ms-grid-rows: repeat(13, 1fr);
}

.row-3-grid .btn {
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 1px 6px;
    width: 180px;
    text-align: left;
}


.col-1-grid .btn {
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 2px 6px 4px 6px;
    text-align: left;
}

.flex-spaced-evenly {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.flex-spaced-evenly > div{
    flex: 0.1 1 auto;
    margin: 10px;
}

.flex-squares {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
    --flex-square-size: 127px;
}

.flex-squares > * {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    margin: 10px;
    height: var(--flex-square-size);
    width: var(--flex-square-size);

}


.flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
}

/* To make columns in a row equal height */
.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

.flex-auto-wrap {
    display: inline-flex;
    flex-wrap: wrap;
}
.flex-auto-wrap > * {
    flex: -1 1 8em;
}