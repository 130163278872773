#adminNavbarCollapse .navLink {
    color: var(--color-white);
}
#adminNavbarCollapse .dropdown-menu > li > a {
    color: var(--color-white);
}

#adminNavbarCollapse li.dropdown.open {
    color: var(--color-white);
}
#adminNavbarCollapse ul.nav > li.dropdown.open > a {
    background-color: transparent;
    color: var(--color-white);
}

#adminNavbarCollapse ul.dropdown-menu {
    background-color: #000;
    color: var(--color-white);
}

#adminNavbar li.dropdown.open a:hover {
    color: #000;
    background-color: var(--color-white);
}

#adminNavbarCollapse > ul > li.active > a {
    background-color: var(--color-primary);
    color: var(--color-white);
    border-bottom: 1px solid var(--color-primary);
}

#adminNavbarCollapse {
    overflow: visible;
    text-align: left;
}

#adminNavbar .navbar-brand {
    margin-left: 5px;
    margin-top: 0px;
}

#adminNavbar {
    border: none;
    height: 20px;
}

.app-admin {
    --leftNavWidth: 0px;
    margin-top: 32px;
}

#page-header {
    color: var(--color-primary);
    background-color: var(--color-white);
}

div#primaryContentArea {
    margin-top: 85px;
    background-color: var(--background);
}

a[href=""] {
    color: inherit;
    cursor: default;
}

#adminNavbar {
    #serviceMenu {
        /* Align the menu to the right instead of with the menus */
        justify-items: end;
    }

    #serviceMenus {
        display: flex;
        gap: 8px;
        flex-direction: row;
        align-items: center;

        li {
            &:first-child {
                order: 1;
            }

            &:last-child {
                order: 0;
            }
        }
    }
}

#page-header {
    padding-top: 4px;
}