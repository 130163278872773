.push-alert[data-alert-type="danger"] {
    --push-alert__foreground: var(--color-white);
    --push-alert__accent: var(--color-danger);
}

.push-alert[data-alert-type="success"] {
    --push-alert__foreground: var(--color-white);
    --push-alert__accent: var(--color-success);
}

.push-alert {
        position: fixed;
            width: calc(100% - var(--leftNavWidth));
    background-color: var(--neutrals-n4);
    color: var(--neutrals-n12);
    border-bottom: 1px solid var(--push-alert__foreground);
    font-size: 14px;
    z-index: 2000;
    border-bottom: 1px solid var(--push-alert__accent);
}

.push-alert__message {
    padding: 24px;
}

.push-alert__intro {
    background-color: var(--push-alert__accent);
    color: var(--push-alert__foreground);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


#dismiss-push-alert {
    color: var(--color-gray-big-stone);
    cursor: pointer;
}

#dismiss-push-alert:hover {
    color: var(--push-alert__foreground);
}


#push-alert-msg {
    display: grid;
    align-items: center;
    grid-template-columns: 50px 1fr 40px;
}

/* Handle the breadcrumb bar*/
.admin .push-alert__message {
    padding: 36px;
}

#dismiss-push-alert {
    color: var(--color-gray-big-stone);
    cursor: pointer;
}
