:root {
    --fc-border-color: var(--color-gray-night-rider);
    --fc-font-color: var(----color-gray-big-stone);
    --fc-font-secondary-color: var(--color-gray-night-rider);
}

.page-scheme-umbra {
    --fc-border-color: var(--color-gray-chateau);
    --fc-font-secondary-color: var(--color-gray-chateau);
    --fc-height: 30px;
    --fc-border-radius: 3px;
}

.page-scheme-terrene {
    --fc-border-color: var(--neutrals-n4);
    --fc-font-secondary-color: var(--neutrals-n4);
    --fc-height: 30px;
    --fc-border-radius: 4px;    
    --fc-font-color: var(--neutrals-n12);
}

.form-control {
    border: 1px solid var(--fc-border-color);
    border-radius: var(--fc-border-radius);
    color: var(--fc-font-color);
}

.form-control + .input-group-addon {
    border: 1px solid var(--fc-border-color);
    border-radius: var(--fc-border-radius);
}

.form-control:focus {
    border-color: var(--fc-font-color);
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(51, 51, 51, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(51, 51, 51, 0.6);
}

.form-dropdown .dropdown-button {
    background-color: var(--color-white);
    box-shadow: 1px 1px 2px rgba(136, 136, 136, 0.5);
    color: var(--fc-font-color);
    cursor: pointer;
    text-align: center;
}

.form-dropdown.btn-group.open .dropdown-button {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: none;
    box-shadow: none;
}

.form-dropdown .dropdown-menu {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-color: var(--fc-border-color);
    border-top: none;
    margin-top: 0;
}

.page-scheme-umbra .form-control,
.page-scheme-umbra .bootstrap-tagsinput,
.page-scheme-umbra .multiselect.btn,
.page-scheme-umbra .chosen-container-single .chosen-single,
.page-scheme-umbra #pageContainer span.select2-selection.select2-selection--single {
    /* Has to use !important because it needs to
    override a style in platform.css which has them too */
    border: 1px solid var(--fc-border-color) !important;
    border-radius: 3px !important;
    height: var(--fc-height);
    box-shadow: none;
    line-height: 16px;
}

.page-scheme-umbra .chosen-container .chosen-drop {
    border-color: var(--fc-border-color);
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.page-scheme-umbra .chosen-container-single .chosen-single {
    color: var(--fc-font-color);
    line-height: 24px;
}

.page-scheme-umbra .chosen-container .chosen-results li.highlighted,
.page-scheme-umbra .select2-container--default .select2-results__option--highlighted[aria-selected],
.page-scheme-umbra .dropdown-menu > li > a:hover,
.page-scheme-umbra .dropdown-menu > li > a:focus {
    background-color: var(--selection-background-color);
    opacity: 1;
    color: var(--color-gray-night-rider);
}
.page-scheme-umbra .input-group .form-control {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.page-scheme-umbra .input-group .form-control.with-addon {
    border-radius: 0 !important;
}

.page-scheme-umbra .input-group-addon.front-addon {
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    height: var(--fc-height);
    padding: 3px 12px;
}

.page-scheme-umbra .input-group-addon {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    height: var(--fc-height);
    padding: 3px 12px;
}

.page-scheme-umbra textarea.form-control {
    height: auto;
}

.page-scheme-umbra #pageContainer span.select2-selection.select2-selection--single {
    box-shadow: none;
}

.page-scheme-umbra .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 27px;
    color: var(--fc-font-color);
}

.page-scheme-umbra #pageContainer span.select2-selection.select2-selection--single {
    padding-top: 0;
}

.page-scheme-umbra .multiselect.dropdown-button {
    color: var(--fc-font-color);
    /* Force the button to be the input height */
    height: var(--fc-height);
}

.page-scheme-umbra .multiselect.btn .caret {
    float: right;
    margin-top: 7px;
    color: var(--color-gray-chateau);
}

.page-scheme-umbra .chosen-container-single .chosen-single div b {
    color: var(--color-gray-chateau);
}

.page-scheme-umbra .form-dropdown .dropdown-button .caret {
    color: var(--color-gray-chateau);
}

.page-scheme-umbra .input-group-addon {
    border-color: var(--fc-border-color);
    border-radius: 3px;
}
.page-scheme-umbra .input-group-btn .btn-default {
    border-color: var(--fc-border-color);
}

.page-scheme-umbra .input-group-btn .btn {
    /* Force the button to be the input height */
    height: var(--fc-height);
}

.page-scheme-umbra .lotacon.help-icon {
    color: var(--color-gray-big-stone);
    text-align: center;
}

.page-scheme-umbra .dropdown-menu > .active > a {
    background-color: var(--color-white);
    color: #000000;
}
/* specific to audience stats */
.page-scheme-umbra .dropdown-menu > .monthDropdown.active > a {
    color: var(--color-white-gainsboro);
    background-color: var(--color-primary);
}

/*
    Hide the standard checkboxes
 */
.page-scheme-umbra .with-icons .multiselect-container input {
    clip: rect(0, 0, 0, 0);
    width: 100%;
}

.page-scheme-umbra .with-icons .multiselect-container .icon-checkbox {
    top: 0;
}

.page-scheme-umbra #multiSelectContainer .btn {
    text-align: left;
}

.page-scheme-umbra #multiSelectContainer .dropdown-toggle.btn-default {
    color: var(--color-gray-chateau);
}

.page-scheme-umbra #multiSelectContainer .open > .dropdown-toggle.btn-default {
    background-color: var(--color-white);
    box-shadow: none;
    color: var(--color-gray-chateau);

    /* Has to use !important because it needs to
     override a style in platform.css and above which have them too */
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.page-scheme-umbra #multiSelectContainer .dropdown-menu {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.page-scheme-umbra .with-icons .multiselect-container .checkbox {
    padding-left: 10px;
}

.text-area-resize-both {
    resize: both;
}

.text-area-resize-none {
    resize: none;
}
