:root {
    /* Named via https://www.color-blindness.com/color-name-hue/ */

    --color-white: #ffffff;
    --color-white-alabaster: #fafafa;
    --color-white-smoke: #f3f3f3;
    --color-white-gainsboro: #e7e7e7;
    
    --color-gray-chateau: #999ea3;
    --color-gray-big-stone: #333e48;  /* n10 */
    --color-gray-night-rider: #333333;

    --color-red-alizarin: #e32726;
    --color-red-thunderbird: #c71818;

    --color-orange-dark-tangerine: #f99e1d; /* --orange-o3 */

    --color-yellow-cornsilk: #fff5d7;
    --color-yellow-tangerine: #ffc708;
    --color-yellow-golden-poppy: #ffc600;
    --color-yellow-forget-me-not: #fef5e9;

    --color-green-very-faint: #f9fbf5;
    --color-green-snowy-mint: #e0f0cc;
    --color-green-feijoa: #c3dd89;
    --color-green-pixie-green: #c1d5a9;
    --color-green-apple: #79b843;
    --color-green-sushi: #6cab36;
    --color-green-observatory: #009175;
    
    --color-blue-cosmic-latte: #e5f8fa;
    --color-blue-regent-st-blue: #9dd7d8;
    --color-blue-smalt-blue: #4c5f6b;
    --color-blue-robin-egg: #00bdd4;
    --color-blue-dark-turquoise: #00bed6;

    --color-violet-misty-rose: #ffe6e6;
    --color-violet-light-quartz: #fbf6fc;
    --color-violet-persian-pink: #ef6eba;
    --color-violet-deep-cerise: #e31d93;
    --color-violet-electric-purple: #bd10e0;
    --color-violet-medium-orchid: #b34fc5;

    /* Yellow Suit */
    --background: #F9FAFC;
    --neutrals-white: #FFFFFF;
    --neutrals-n0: var(--neutrals-white);
    --neutrals-n1: #F9FAFC;
    --neutrals-n2: #F6F8FA;
    --neutrals-n3: #EFF1F3;
    --neutrals-n4: #E5E8EB;
    --neutrals-n5: #D8DBDF;
    --neutrals-n6: #C2C7CD;
    --neutrals-n7: #ACB3BB;
    --neutrals-n8: #838E99;
    --neutrals-n9: #596776;
    --neutrals-n10: #283C52;
    --neutrals-n11: #11253C;
    --neutrals-n12: #081025;

    --primary-p1: #EDF4EF;
    --primary-p2: #BCD1C0;
    --primary-p3: #4CA47E;
    --primary-p4: #007643;
    --primary-p4-light: #00764326;
    --primary-p5: #18281A;

    --yellow-y1: #FBEFDB;
    --yellow-y2: #F9C66F;
    --yellow-y3: #F5B03B;
    --yellow-y4: #DE9211;

    --orange-o1: #FBEFDB;
    --orange-o2: #F9C66F;
    --orange-o3: #F8AC2B;
    --orange-o4: #DE9211;

    --red-r1: #FAC8C5;
    --red-r2: #F28780;
    --red-r3: #E14C43;
    --red-r4: #C82B21;

    --pink-p3: #D64884;
    --pink-p2: #EA73A4;
    --pink-p1: #EFB3D1;

    --blue-b1: #C4DBF5;
    --blue-b2: #71A7E6;
    --blue-b3: #3C80D0;
    --blue-b4: #0C6CD7;

    --aqua-a3: #65B4E0;
    --aqua-a2: #8FCDF0;
    --aqua-a1: #BADFF4;

    --purple-pr3: #7733E7;
    --purple-pr2: #A36CEA;
    --purple-pr1: #D2B5F7;

    --chart-pink-p1: #D84C91;
    --chart-blue-b1: #3C80D0;
    --chart-yellow-y1: #F1AF3F;

    --entity-color-audiences: var(--color-green-sushi);
    --entity-color-behaviors: var(--color-blue-dark-turquoise);
    --entity-color-hierarchies: var(--color-violet-electric-purple);
    --entity-color-verticals: var(--color-violet-deep-cerise);
    --entity-color-campaigns: var(--color-blue-regent-st-blue);
    --entity-color-models: var(--color-orange-dark-tangerine);
    --entity-color-profiles: var(--color-green-pixie-green);
    --entity-color-system: #474747;

    --data-type-color-branded: var(--yellow-y1);
    --data-type-color-custom: var(--blue-b1);
    --data-type-color-standard: var(--aqua-a1);
    --data-type-color-shared: var(--purple-pr1);
    --data-type-color-showcased: var(--purple-pr1); 

    --navbar-color: #454545;
    --chart-color-1: var(--chart-pink-p1);
    --chart-color-2: var(--chart-blue-b1);
    --chart-color-3: var(--chart-yellow-y1);

    --color-green-lotame: var(--primary-p4);
    
    --color-primary: var(--primary-p4);
    --color-primary-normal: var(--primary-p4);
    --color-primary-lighter: var(--primary-p3);
    --color-primary-darker: var(--primary-p5);

    --color-secondary: var(--blue-b3);

    --color-tertiary: var(#ffffff);

    --color-default: var(--neutrals-n9);
    --color-warning: var(--orange-o4);
    --color-success: var(--primary-p3);
    --color-info: var(--blue-b3);
    --color-muted: var(--neutrals-n8);

    --color-danger: var(--red-r3);


    --color-danger-normal: var(--color-danger);
    --color-danger-lighter: var(--red-r2);
    --color-danger-darker: var(--red-r4);

    /*https://angel-rs.github.io/css-color-filter-generator!*/
    --filter-primary: brightness(0) saturate(100%) invert(14%) sepia(90%) saturate(4140%) hue-rotate(157deg) brightness(105%) contrast(101%);
    --filter-disabled: brightness(0) saturate(100%) invert(62%) sepia(7%) saturate(645%) hue-rotate(169deg) brightness(89%) contrast(84%);
    --filter-warning: brightness(0) saturate(100%) invert(79%) sepia(41%) saturate(6612%) hue-rotate(3deg) brightness(96%) contrast(87%);
    /* --neutrals-n6 */
    --filter-muted: brightness(0) saturate(100%) invert(100%) sepia(18%) saturate(2419%) hue-rotate(175deg) brightness(86%) contrast(83%);
    /* --navigation-green-1: #021F05;
    --navigation-green-2: #0E3612; */
}
